import React, {useState} from 'react';
import {Row, Col, Button, Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import emailjs from 'emailjs-com';
import { batch } from '../data/location';

const AlumniForm = props => {
    const batchData = batch();

    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);
    const [formData, setFormData] = useState({ 
        name: '', 
        email: '',
        mobile: '',
        address: '',
        batch: '',
        academic: '',
        workdetail: '',
        photo: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value});
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.mobile) {
            newErrors.mobile = 'Contact number is required';
          }
          // Check if it is a valid format (example: 10 digits)
          else if (!/^\d{10}$/.test(formData.mobile)) {
            newErrors.mobile = 'Contact number must be 10 digits';
          }   
          if (!formData.email) {
            newErrors.email = 'Email is required';
          }
          else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is invalid';
          }   
        if (!formData.address) newErrors.address = 'Address is required';
        if (!formData.batch) newErrors.batch = 'Please select batch';
        if (!formData.academic) newErrors.academic = 'Current academic details required';
        if (!formData.workdetail) newErrors.workdetail = 'Current working details required';
        if (!formData.photo) newErrors.photo = 'Please attach photo';
        return newErrors;
      };

      const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
        } else {          
          console.log('Form submitted successfully:', formData); // This is where the submission logic goes
          emailjs.send('service_1l5n4t8', 'template_af5saz9', formData, 'khP-Wp7AckcNLRvuY')
            .then((response) => {
            console.log('Email sent successfully!', response.status, response.text);
            setSuccess(true);
            setFormData({ 
                name: '', 
                email: '',
                mobile: '',
                address: '',
                batch: '',
                academic: '',
                workdetail: '',
                photo: ''

            }); // Reset form
            setErrors({});
            // setFormData(formData);
            })
            .catch((error) => {
                 console.error('Error sending email:', error);
                 setErrors({});
            });
        }
    };

    return(
        <>
            <Form onSubmit={handleSubmit}>
                <div className='mb-3'>
                    <Row>
                        <Col sm="4">
                            <label><b>Name</b></label>                       
                        </Col>
                        <Col sm="8">
                            <input
                            type="text"
                            name="name"
                            placeholder="Enter name"
                            value={formData.name}
                            onChange={handleChange}
                            className='form-control'
                            />
                        </Col>
                        {errors.name && <span style={{ color: 'red', textAlign:'right' }}>{errors.name}</span>}
                    </Row>                        
                </div>
                <div className='mb-3'>
                    <Row>
                        <Col sm="4">
                            <label><b>Email ID</b></label>                       
                        </Col>
                        <Col sm="8">
                            <input
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            value={formData.email}
                            onChange={handleChange}
                            className='form-control'
                            />
                        </Col>
                        {errors.email && <span style={{ color: 'red', textAlign:'right' }}>{errors.email}</span>}
                    </Row>                        
                </div>
                <div className='mb-3'>
                    <Row>
                        <Col sm="4">
                            <label><b>Mobile No.</b></label>                       
                        </Col>
                        <Col sm="8">
                            <input
                            type="text"
                            name="mobile"
                            placeholder="Enter mobile Number"
                            value={formData.mobile}
                            onChange={handleChange}
                            className='form-control'
                            />
                        </Col>
                        {errors.mobile && <span style={{ color: 'red', textAlign:'right' }}>{errors.mobile}</span>}
                    </Row>                        
                </div>
                <div className='mb-3'>
                    <Row>
                        <Col sm="4">
                            <label><b>Address</b></label>                       
                        </Col>
                        <Col sm="8">
                            <textarea
                            row="3"
                            name="address"
                            placeholder="Enter your address"
                            value={formData.address}
                            onChange={handleChange}
                            className='form-control'
                            />
                        </Col>
                        {errors.address && <span style={{ color: 'red', textAlign:'right' }}>{errors.address}</span>}
                    </Row>                        
                </div>
                <div className='mb-3'>
                    <Row>
                        <Col sm="4">
                            <label><b>Your Batch @ ICAD</b></label>                       
                        </Col>
                        <Col sm="8">
                            <select
                            name="batch"
                            value={formData.batch}
                            onChange={handleChange}
                            className='form-control'
                            >
                            <option value="">Select Batch</option>
                            {batchData.map((batches) => (
                                <option key={batches} value={batches}>
                                {batches}
                                </option>
                            ))}
                            </select>
                        </Col>
                        {errors.batch && <span style={{ color: 'red', textAlign:'right' }}>{errors.batch}</span>}
                    </Row>                        
                </div>
                <div className='mb-3'>
                    <Row>
                        <Col sm="4">
                            <label><b>Current Academic Details</b></label>                       
                        </Col>
                        <Col sm="8">
                            <input
                            type="text"
                            name="academic"
                            placeholder="Enter Current Academic Details"
                            value={formData.academic}
                            onChange={handleChange}
                            className='form-control'
                            />
                        </Col>
                        {errors.academic && <span style={{ color: 'red', textAlign:'right' }}>{errors.academic}</span>}
                    </Row>                        
                </div>
                <div className='mb-3'>
                    <Row>
                        <Col sm="4">
                            <label><b>Current Working Details</b></label>                       
                        </Col>
                        <Col sm="8">
                            <textarea
                            row="3"
                            name="workdetail"
                            placeholder="Enter Current Working Details"
                            value={formData.workdetail}
                            onChange={handleChange}
                            className='form-control'
                            />
                        </Col>
                        {errors.workdetail && <span style={{ color: 'red', textAlign:'right' }}>{errors.workdetail}</span>}
                    </Row>                        
                </div>
                <div className='mb-3'>
                    <Row>
                        <Col sm="4">
                            <label><b>Your Recent Photo</b></label>                       
                        </Col>
                        <Col sm="8">
                            <input
                            type="file"
                            name="photo"
                            // placeholder="Enter Current Working Details"
                            value={formData.photo}
                            onChange={handleChange}
                            className='form-control'
                            />
                        </Col>
                        {errors.photo && <span style={{ color: 'red', textAlign:'right' }}>{errors.photo}</span>}
                    </Row>                        
                </div>
                
                <hr/> 
                <div className='d-flex justify-content-end'>
                    <Button className='theme_btn d-block mx-auto' type="submit" size='lg'>
                        Submit
                    </Button>
                </div>           
            </Form>
        </>
    );
};

export default AlumniForm;