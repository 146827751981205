import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Card, Image} from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Navigation from '../components/Navigation';
import {useParams} from "react-router-dom";
import icadiansData from '../data/knowIcadians';
import parse from 'html-react-parser';


const IcadiansDetails = props => {
    const {icadiansPersonId} = useParams();
    const thisPerson = icadiansData.find(person => person.id === icadiansPersonId);
    console.log(thisPerson);
    return(
        <main>
            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>Icadians Profile</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/know-icadians">Know Icadians</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Icadians Profile</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="mt-50" data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <Row>
                        <Col md={3} sm={12} className='mb-small'>
                            <div className='thumbnail'>
                                <Image src={thisPerson.photo} alt="student image" className='d-block mx-auto' fluid></Image>
                            </div>
                        </Col>
                        <Col md={9} sm={12}>
                            <h1 className='title'>{thisPerson.name}</h1>
                            <h5>{thisPerson.profile}</h5>
                            <hr/>
                            <p>{parse (thisPerson.info) }</p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    )
}

export default IcadiansDetails;