export default [
    {
        id: "1",
        name:"Anuj Apte",
        photo:require('../assets/images/students/anujapte.png'),
        profile:"Physics Prodigy of India",
        info:"<p>Anuj Apte, a physics prodigy from Nagpur, India, demonstrated academic excellence from his school days at Somalwar, Nikalas. He became the first student from Vidarbha to achieve a remarkable feat of winning three medals in various International Olympiads for India.</p><p>Anuj's journey began with joining ICAD for JEE training in VIII. Notably, he qualified for the Indian National Physics Olympiad (INPhO) in XI, a rare accomplishment as only two XI standard candidates from India were selected. Anuj finished studying Physics up to the post-graduation level during his XII year.</p><p>In XII, Anuj was selected for the Asian Physics Olympiad and International Physics Olympiad, earning recognition as the top student in the Indian Camp. Despite the pressure of JEE (Advanced) preparation, Anuj sacrificed the last 20 days to attend the Indian camp, where he represented India at the Asian Physics Olympiad in China and won a Gold Medal.</p><p>Although Anuj could have secured a top rank in JEE (Advanced) with focused preparation, he chose to prioritize the international physics competitions. His decision to attend the Asian Physics Olympiad paid off with a Gold Medal but impacted his JEE (Advanced) ranking, landing him at AIR 79.</p><p>In the International Physics Olympiad, Anuj narrowly missed the Gold Medal but still achieved a remarkable Silver Medal. Despite having the opportunity to pursue B. Tech in Computer Science at IIT-Powai, Anuj decided to follow his passion for physics and opted for admission to the Indian Institute of Sciences (IISC) Bangalore.</p><p>Anuj's story is one of academic excellence, dedication to physics, and the pursuit of passion over conventional success, showcasing his commitment to his chosen field.</p>"
    },
    {
        id: "2",
        name:"Rupanshu Ganvir",
        photo:require('../assets/images/students/rupanshuganvir.png'),
        profile:"Amazing Talent in Physics and Mathematics",
        info:"<p>Rupanshu Ganvir, an exceptional talent in physics and mathematics, hails from NEERI Modern School, Nagpur. His academic journey reflects his prowess and dedication to excellence.<p><p>In VIII, Rupanshu qualified for the National Talent Search Examination (NTSE), showcasing his early aptitude for academic achievements. He joined ICAD in Xth and went on to qualify for the Indian National Mathematics Olympiad (INMO) in XI, securing a position among the top 30 students in India.</p><p>Rupanshu's academic achievements include success in various prestigious competitive exams. Despite narrowly missing the Chemistry Olympiad, he became the first candidate from Nagpur to qualify for the Indian National Mathematics Olympiad (INMO), Indian National Physics Olympiad (INPhO), and KVPY.</p><p>Despite losing crucial 50 days of JEE preparation due to Olympiad commitments, Rupanshu achieved an impressive 24th All India Rank in JEE (Advanced) in the General Category and secured the FIRST All India Rank in the Reserved Category. He also excelled in JEE (Main) 2015 as the Nagpur topper with a score of 326/360.</p><p>In the Maharashtra State Board (HSSC) Examination, Rupanshu stood FIRST MERIT in the reserved category, further highlighting his academic excellence. Later, he represented India in the International Physics Olympiad (INPhO) in Kazakhstan, where he won a Silver Medal, contributing to India's success on the international stage. Rupanshu's journey is a testament to his exceptional abilities in both mathematics and physics, showcasing his commitment to academic excellence and success.</p>"
    },
    {
        id: "3",
        name:"Pranjal Warade",
        photo:require('../assets/images/students/pranjalwarade.png'),
        profile:"Maths Prodigy in Girls by TOI",
        info:"<p>Pranjal Warade, recognized as the most talented girl in Mathematics in 2015-16 by TOI, made a significant mark in the field.</p><p>After completing her school days, Pranjal decided to pursue her passion for Mathematics and began her preparation for the Indian National Mathematics Olympiad (INMO). She not only qualified for INMO but also earned a spot to represent India at the European Girls Mathematics Olympiad in Belarus, where she secured a Bronze Medal. Pranjal holds the distinction of being the FIRST Indian girl to represent the country and win a medal in this competition.</p><p>In addition to her achievements, Pranjal went on to secure a Bronze Medal in the International Mathematics Olympiad (IMO) 2016, further showcasing her exceptional mathematical skills. She successfully cracked the Kishore Vaigyanik Protsahan Yojana (KVPY) finals and chose to pursue her academic journey at the prestigious Indian Institute of Science (IISc), Bangalore, opting for it over the option of joining the Indian Institutes of Technology (IITs).</p><p>Pranjal's accomplishments underscore her dedication to mathematics and highlight her trailblazing achievements as a talented mathematician and a pioneer for Indian girls in international mathematics competitions.</p>"
    },
    {
        id: "4",
        name:"Ishan Khot",
        photo:require('../assets/images/students/ishankhot.png'),
        profile:"Mathematics Genius",
        info:"<p>Ishan Khot, a mathematics genius and scholar from Bhartiya Vidya Mandir, Nagpur, achieved remarkable success in various competitive exams.</p><p>Born to doctors Mr. Sanjay and Mrs. Rajashree Khot, with his mother being the first merit in Maharashtra for X and XII examinations, Ishan aimed to follow in the footsteps of his elder brother, Rohan Khot, who studied B. Tech. Computer Science at IITG and secured a placement in Google, California, USA.</p><p>Ishan became the first ICADian to secure a position within the top 100 All India Ranks in JEE (Advanced) with an impressive AIR 94. His journey involved excelling in exams like RMO, AMTI (AIR 1), and INMO, where he was among the first 30 students selected for the INMO camp.</p><p>Despite initial challenges in Chemistry, Ishan received special training from ICAD staff and successfully cracked the Chemistry Olympiad. His disciplined lifestyle, focusing on 8-10 hours of daily study, contributed to his consistent performance over two years, securing his place in the top 100 clubs of JEE.</p><p>Ishan pursued B. Tech. in Computer Science from IIT-Delhi and achieved direct placement with Facebook, culminating in a successful academic and professional journey. Ishan's story highlights his dedication, academic prowess, and success in both competitive exams and career placement.</p>"
    }
];