export const gallerySeminar = () => { 
    return [
        {
        id:"1",
        type:"Chandrapur",      
        subtype:[
                {
                    year:'2023',
                    images: [
                        require('../assets/images/photographs/seminar/chandrapur1.jpg'),
                        require('../assets/images/photographs/seminar/chandrapur2.jpg'),
                        require('../assets/images/photographs/seminar/chandrapur3.jpg'),
                        require('../assets/images/photographs/seminar/chandrapur4.jpg'),
                        require('../assets/images/photographs/seminar/chandrapur5.jpg')
                    ]
                }       
            ]
        },
        {
            id:"2",
            type:"Nagpur East",      
            subtype:[
                {
                    year:'2023',
                    images: [
                        require('../assets/images/photographs/seminar/nagpur1.jpg'),
                        require('../assets/images/photographs/seminar/nagpur2.jpg'),
                        require('../assets/images/photographs/seminar/nagpur3.jpg')
                    ]
                }    
            ]
        },
        {
            id:"3",
            type:"Residential",      
            subtype:[
                {
                    year:'2023',
                    images: [
                        require('../assets/images/photographs/seminar/res1.jpeg'),
                        require('../assets/images/photographs/seminar/res2.jpeg'),
                        require('../assets/images/photographs/seminar/res3.jpeg'),
                        require('../assets/images/photographs/seminar/res4.jpeg'),
                        require('../assets/images/photographs/seminar/res5.jpeg'),
                        require('../assets/images/photographs/seminar/res6.jpeg'),
                        require('../assets/images/photographs/seminar/res7.jpeg'),
                        require('../assets/images/photographs/seminar/res8.jpeg')
                    ]
                }    
            ]
        }
    ];
};

export const gallaryOrientation = () => { 
    return [
        {
        id:"1",
        type:"Amravati",      
        subtype:[
                {
                    year:'2023',
                    images: [
                        require('../assets/images/photographs/orientation/orientation1.jpeg'),
                        require('../assets/images/photographs/orientation/orientation2.jpeg'),
                        require('../assets/images/photographs/orientation/orientation3.jpeg')
                    ]
                }       
            ]
        }
    ];
};

export const gallaryCpa = () => { 
    return [
        {
        id:"1",
        type:"Amravati",      
        subtype:[
                {
                    year:'2023',
                    images: [
                        require('../assets/images/photographs/cpa/cpa1.jpeg'),
                        require('../assets/images/photographs/cpa/cpa2.jpeg'),
                        require('../assets/images/photographs/cpa/cpa3.jpeg'),
                        require('../assets/images/photographs/cpa/cpa4.jpeg'),
                        require('../assets/images/photographs/cpa/cpa5.jpeg')
                    ]
                }       
            ]
        }
    ];
};

export const gallaryLaunch = () => { 
    return [
        {
        id:"1",
        type:"Yavatmal",      
        subtype:[
                {
                    year:'2023',
                    images: [
                        require('../assets/images/photographs/launch/yt1.jpg'),
                        require('../assets/images/photographs/launch/yt2.jpg'),
                        require('../assets/images/photographs/launch/yt3.jpg'),
                        require('../assets/images/photographs/launch/yt4.jpg'),
                        require('../assets/images/photographs/launch/yt5.jpg'),
                        require('../assets/images/photographs/launch/yt6.jpg'),
                        require('../assets/images/photographs/launch/yt7.jpg'),
                        require('../assets/images/photographs/launch/yt8.jpg'),
                        require('../assets/images/photographs/launch/yt9.jpg'),
                        require('../assets/images/photographs/launch/yt10.jpg'),
                        require('../assets/images/photographs/launch/yt11.jpg'),
                        require('../assets/images/photographs/launch/yt12.jpg'),
                        require('../assets/images/photographs/launch/yt13.jpg'),
                        require('../assets/images/photographs/launch/yt14.jpg'),
                        require('../assets/images/photographs/launch/yt15.jpg')
                    ]
                }       
            ]
        }
    ];
};

export const gallaryLeap = () => { 
    return [
        {
        id:"1",
        type:"Amravati",      
        subtype:[
                {
                    year:'2023',
                    images: [
                        require('../assets/images/photographs/leap/amravati23-1.jpg'),
                        require('../assets/images/photographs/leap/amravati23-2.jpg'),
                        require('../assets/images/photographs/leap/amravati23-3.jpg'),
                        require('../assets/images/photographs/leap/amravati23-4.jpg'),
                        require('../assets/images/photographs/leap/amravati23-5.jpg')
                    ]
                },
                {
                    year:'2017',
                    images: [
                        require('../assets/images/photographs/leap/amravati17-1.jfif'),
                        require('../assets/images/photographs/leap/amravati17-2.jfif'),
                        require('../assets/images/photographs/leap/amravati17-3.jfif'),
                        require('../assets/images/photographs/leap/amravati17-4.jfif'),
                        require('../assets/images/photographs/leap/amravati17-5.jfif'),
                        require('../assets/images/photographs/leap/amravati17-6.jfif'),
                        require('../assets/images/photographs/leap/amravati17-7.jfif'),
                        require('../assets/images/photographs/leap/amravati17-8.jfif'),
                        require('../assets/images/photographs/leap/amravati17-9.jfif'),
                        require('../assets/images/photographs/leap/amravati17-10.jfif'),
                        require('../assets/images/photographs/leap/amravati17-11.jfif'),
                        require('../assets/images/photographs/leap/amravati17-12.jfif'),
                        require('../assets/images/photographs/leap/amravati17-13.jfif'),
                        require('../assets/images/photographs/leap/amravati17-14.jfif'),
                        require('../assets/images/photographs/leap/amravati17-15.jfif')
                    ]
                }       
            ]
        },
        {
            id:"2",
            type:"Chandrapur",      
            subtype:[
                {
                    year:'2023',
                    images: [
                        require('../assets/images/photographs/leap/chandrapur23-1.jpg'),
                        require('../assets/images/photographs/leap/chandrapur23-2.jpg'),
                        require('../assets/images/photographs/leap/chandrapur23-3.jpg'),
                        require('../assets/images/photographs/leap/chandrapur23-4.jpg'),
                        require('../assets/images/photographs/leap/chandrapur23-5.jpg'),
                        require('../assets/images/photographs/leap/chandrapur23-6.jpg')
                    ]
                }      
            ]
        },
        {
            id:"3",
            type:"Gadchiroli",      
            subtype:[
                {
                    year:'2017',
                    images: [
                        require('../assets/images/photographs/leap/gadchiroli1.jfif'),
                        require('../assets/images/photographs/leap/gadchiroli2.jfif'),
                        require('../assets/images/photographs/leap/gadchiroli3.jfif'),
                        require('../assets/images/photographs/leap/gadchiroli4.jfif'),
                        require('../assets/images/photographs/leap/gadchiroli5.jfif'),
                        require('../assets/images/photographs/leap/gadchiroli6.jfif'),
                        require('../assets/images/photographs/leap/gadchiroli7.jfif'),
                        require('../assets/images/photographs/leap/gadchiroli8.jfif')
                    ]
                }      
            ]
        },
        {
            id:"4",
            type:"Nagpur",      
            subtype:[
                {
                    year:'2023',
                    images: [
                        require('../assets/images/photographs/leap/nagpur23-1.jpg'),
                        require('../assets/images/photographs/leap/nagpur23-2.jpg'),
                        require('../assets/images/photographs/leap/nagpur23-3.jpg'),
                        require('../assets/images/photographs/leap/nagpur23-4.jpg'),
                        require('../assets/images/photographs/leap/nagpur23-5.jpg'),
                        require('../assets/images/photographs/leap/nagpur23-6.jpg'),
                        require('../assets/images/photographs/leap/nagpur23-7.jpg'),
                        require('../assets/images/photographs/leap/nagpur23-8.jpg')
                    ]
                }      
            ]
        },
        {
            id:"5",
            type:"Wardha",      
            subtype:[
                {
                    year:'2017',
                    images: [
                        require('../assets/images/photographs/leap/wardha17-1.jfif'),
                        require('../assets/images/photographs/leap/wardha17-2.jfif'),
                        require('../assets/images/photographs/leap/wardha17-3.jfif'),
                        require('../assets/images/photographs/leap/wardha17-4.jfif')
                    ]
                }      
            ]
        },
        {
            id:"6",
            type:"Yavatmal",      
            subtype:[
                {
                    year:'2017',
                    images: [
                        require('../assets/images/photographs/leap/yat17-1.jfif'),
                        require('../assets/images/photographs/leap/yat17-2.jfif'),
                        require('../assets/images/photographs/leap/yat17-3.jfif'),
                        require('../assets/images/photographs/leap/yat17-4.jfif'),
                        require('../assets/images/photographs/leap/yat17-5.jfif'),
                        require('../assets/images/photographs/leap/yat17-6.jfif'),
                        require('../assets/images/photographs/leap/yat17-7.jfif'),
                        require('../assets/images/photographs/leap/yat17-8.jfif')
                    ]
                }      
            ]
        }
    ];
};

export const gallaryResult = () => { 
    return [
        {
        id:"1",
        type:"Nagpur",      
        subtype:[
                {
                    year:'2015',
                    images: [
                        require('../assets/images/photographs/result/nagpur15-1.jfif'),
                        require('../assets/images/photographs/result/nagpur15-2.jfif'),
                        require('../assets/images/photographs/result/nagpur15-3.jfif'),
                        require('../assets/images/photographs/result/nagpur15-4.jfif'),
                        require('../assets/images/photographs/result/nagpur15-5.jfif'),
                        require('../assets/images/photographs/result/nagpur15-6.jfif')
                    ]
                }       
            ]
        }
    ];
};

export const gallaryFeliciation = () => { 
    return [
        {
        id:"1",
        type:"Nagpur",      
        subtype:[
                {
                    year:'2024',
                    images: [
                        require('../assets/images/photographs/feliciation/nagpur24-1.jpg'),
                        require('../assets/images/photographs/feliciation/nagpur24-2.jpg'),
                        require('../assets/images/photographs/feliciation/nagpur24-3.jpg'),
                        require('../assets/images/photographs/feliciation/nagpur24-4.jpg'),
                        require('../assets/images/photographs/feliciation/nagpur24-5.jpg'),
                        require('../assets/images/photographs/feliciation/nagpur24-6.jpg'),
                        require('../assets/images/photographs/feliciation/nagpur24-7.jpg'),
                        require('../assets/images/photographs/feliciation/nagpur24-8.jpg')
                    ]
                }       
            ]
        }
    ];
};

export const gallaryGuidance = () => { 
    return [
        {
        id:"1",
        type:"Nagpur",      
        subtype:[
                {
                    year:'2023',
                    images: [
                        require('../assets/images/photographs/guidance/nagpur23-1.jpg'),
                        require('../assets/images/photographs/guidance/nagpur23-2.jpg'),
                        require('../assets/images/photographs/guidance/nagpur23-3.jpg'),
                        require('../assets/images/photographs/guidance/nagpur23-4.jpg'),
                        require('../assets/images/photographs/guidance/nagpur23-5.jpg'),
                        require('../assets/images/photographs/guidance/nagpur23-6.jpg'),
                        require('../assets/images/photographs/guidance/nagpur23-7.jpg'),
                        require('../assets/images/photographs/guidance/nagpur23-8.jpg')
                    ]
                }       
            ]
        }
    ];
};

export const gallaryScienceVisit = () => { 
    return [
        {
        id:"1",
        type:"Nagpur",      
        subtype:[
                {
                    year:'2023',
                    images: [
                        require('../assets/images/photographs/science-visit/nagpur23-1.jpg'),
                        require('../assets/images/photographs/science-visit/nagpur23-2.jpg'),
                        require('../assets/images/photographs/science-visit/nagpur23-3.jpg'),
                        require('../assets/images/photographs/science-visit/nagpur23-4.jpg'),
                        require('../assets/images/photographs/science-visit/nagpur23-5.jpg'),
                        require('../assets/images/photographs/science-visit/nagpur23-6.jpg'),
                        require('../assets/images/photographs/science-visit/nagpur23-7.jpg'),
                        require('../assets/images/photographs/science-visit/nagpur23-8.jpg')
                    ]
                }       
            ]
        }
    ];
};