import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


const Modal = ({ isOpen, onClose, title, body, size }) => {
  const sizeClass = size ? `modal-${size}` : '';
  return (
    <div className={`modal fade ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className={`modal-dialog ${sizeClass}`} role="document">
        <div className="modal-content">
          <div className="modal-header pdf_header">
            <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
            <button type="button" class="btn-close" aria-label="Close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            {body}
          </div>
        </div>
      </div>
    </div>
  );
};
  
export default Modal;