import React, {useState} from 'react';
import { Container, Row, Col, Tabs, Tab, Accordion } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Navigation from '../components/Navigation';

import Breadcrumb from 'react-bootstrap/Breadcrumb';

import result24 from '../assets/images/results/medical-result24.webp';
import result23 from '../assets/images/results/medical-result23.jpg';
import result22 from '../assets/images/results/medical-result22.webp';
import result21 from '../assets/images/results/medical-result21.jpg';
import result20 from '../assets/images/results/medical-result20.jpeg';
import result19 from '../assets/images/results/medical-result19.webp';
import result18 from '../assets/images/results/medical-result18.jpg';
import result17 from '../assets/images/results/medical-result17.jpg';

const MedicalResult = props => {
    return(
        <>
            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>Medical Result</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/results">Results</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Medical Result</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <Tabs
                        defaultActiveKey="one"
                        id="justify-tab-example"
                        className="mb-3 horizontal_tabs"
                        justify
                        >
                        <Tab eventKey="one" title="2024">
                            <h1 className='title mt-50'>Medical Result - 2024</h1>
                            <Image src={result24} alt="result 2024" className='d-block mx-auto mt-3' fluid></Image>
                        </Tab>
                        <Tab eventKey="two" title="2023">
                            <h1 className='title mt-50'>Medical Result - 2023</h1>
                            <Image src={result23} alt="result 2023" className='d-block mx-auto mt-3' fluid></Image>
                        </Tab>
                        <Tab eventKey="three" title="2022">
                            <h1 className='title mt-50'>Medical Result - 2022</h1>
                            <Image src={result22} alt="result 2022" className='d-block mx-auto mt-3' fluid></Image>
                        </Tab>
                        <Tab eventKey="four" title="2021">
                            <h1 className='title mt-50'>Medical Result - 2021</h1>
                            <Image src={result21} alt="result 2021" className='d-block mx-auto mt-3' fluid></Image>
                        </Tab>
                        <Tab eventKey="five" title="2020">
                            <h1 className='title mt-50'>Medical Result - 2020</h1>
                            <Image src={result20} alt="result 2020" className='d-block mx-auto mt-3' fluid></Image>
                        </Tab>
                        <Tab eventKey="six" title="2019">
                            <h1 className='title mt-50'>Medical Result - 2019</h1>
                            <Image src={result19} alt="result 2019" className='d-block mx-auto mt-3' fluid></Image>
                        </Tab>
                        <Tab eventKey="seven" title="2018">
                            <h1 className='title mt-50'>Medical Result - 2018</h1>
                            <Image src={result18} alt="result 2018" className='d-block mx-auto mt-3' fluid></Image>
                        </Tab>
                        <Tab eventKey="eight" title="2017">
                            <h1 className='title mt-50'>Medical Result - 2017</h1>
                            <Image src={result17} alt="result 2017" className='d-block mx-auto mt-3' fluid></Image>
                        </Tab>
                    </Tabs>
                </Container>
            </section>
        </>
    );
};

export default MedicalResult;