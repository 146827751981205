import image1 from '../assets/images/blog/blog1.jpg';

export default [
    {
        id:"1",
        category:"JEE",
        image:image1,
        date:'01 Oct 2024',
        title:"'ICAD Admission Assist' - Placing ICADians to its final destination with college and branch Selection assistance",
        detail:"<p>Few years back, I just called residential students in different parts of Vidarbha after they went back after completing the JEE program in ICAD. When asked their parents about the whereabouts of their wards, their answer is - Sir, he seems to be so confused. He and his friends are trying to find out proper colleges based on their scores.</p><p>I was literally shaken up. It is a little bit easy for we people in the city to explore colleges and branches through our contacts. But, what about these students? Also, most of the students don't have a clue about the branches and promising colleges. For the majority, it is a guess work. If at this stage, students don't receive proper advice, there is a possibility that he/she will land up in an inappropriate college or improper branch.</p>"
    },
    {
        id:"2",
        category:"Medical",
        image:image1,
        date:'15 Sept 2024',
        title:"'ICAD Admission Assist' - Placing ICADians to its final destination with college and branch Selection assistance",
        detail:"<p>Few years back, I just called residential students in different parts of Vidarbha after they went back after completing the JEE program in ICAD. When asked their parents about the whereabouts of their wards, their answer is - Sir, he seems to be so confused. He and his friends are trying to find out proper colleges based on their scores.</p><p>I was literally shaken up. It is a little bit easy for we people in the city to explore colleges and branches through our contacts. But, what about these students? Also, most of the students don't have a clue about the branches and promising colleges. For the majority, it is a guess work. If at this stage, students don't receive proper advice, there is a possibility that he/she will land up in an inappropriate college or improper branch.</p>"
    },
    {
        id:"3",
        category:"Foundation",
        image:image1,
        date:'01 Oct 2024',
        title:"'ICAD Admission Assist' - Placing ICADians to its final destination with college and branch Selection assistance",
        detail:"<p>Few years back, I just called residential students in different parts of Vidarbha after they went back after completing the JEE program in ICAD. When asked their parents about the whereabouts of their wards, their answer is - Sir, he seems to be so confused. He and his friends are trying to find out proper colleges based on their scores.</p><p>I was literally shaken up. It is a little bit easy for we people in the city to explore colleges and branches through our contacts. But, what about these students? Also, most of the students don't have a clue about the branches and promising colleges. For the majority, it is a guess work. If at this stage, students don't receive proper advice, there is a possibility that he/she will land up in an inappropriate college or improper branch.</p>"
    }
];