export default [
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-0.jpg')},
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-1.jpg')},
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-2.jpg')},
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-3.jpg')},
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-4.jpg')},
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-5.jpg')},
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-6.jpg')},
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-7.jpg')},
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-8.jpg')},
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-9.jpg')},
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-10.jpg')},
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-11.jpg')},
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-12.jpg')},
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-13.jpg')},
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-14.jpg')},
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-15.jpg')},
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-16.jpg')},
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-17.jpg')},
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-18.jpg')},
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-19.jpg')},
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-20.jpg')},
    { pageImg: require('../assets/images/documents/C-12D-Aromatic-Hydrocarbon/C-12D-Aromatic-Hydrocarbon-images-20.jpg')}
]