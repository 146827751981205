import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Image from 'react-bootstrap/Image';
import Navigation from '../components/Navigation';

import galleryImg1 from '../assets/images/gallery/seminar.jpg';
import galleryImg2 from '../assets/images/gallery/pre-orientation.jpg';
import galleryImg3 from '../assets/images/gallery/cpa.jpg';
import galleryImg4 from '../assets/images/gallery/lunch.jpg';
import galleryImg5 from '../assets/images/gallery/workshop.jpg';
import galleryImg6 from '../assets/images/gallery/result.jpg';
import galleryImg7 from '../assets/images/gallery/felicication.jpg';
import galleryImg8 from '../assets/images/gallery/guidance.jpg';
import galleryImg9 from '../assets/images/gallery/science.jpg';

const Gallery = props => {
    return(
        <>
            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>Gallery</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Gallery</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50'>
                <Container>
                    <h2 className='title mb-30'>Gallery</h2>
                    <Row>
                        <Col sm={6} className='mb-15' data-aos="fade-up" data-aos-duration="3000">
                            <div className='media_card'>
                                <div className='media_img'>
                                    <Image src={galleryImg1} alt='gallery_img' fluid></Image>
                                </div>
                                <div className='img_overlay'>
                                    <h4><a href="/gallery-details#seminar">View More</a></h4>
                                </div>
                                <div className='media_text'>
                                    <h2 className='subtitle'>seminar</h2>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} className='mb-15' data-aos="fade-up" data-aos-duration="3000">
                            <div className='media_card'>
                                <div className='media_img'>
                                    <Image src={galleryImg2} alt='gallery_img' fluid></Image>
                                </div>
                                <div className='img_overlay'>
                                    <h4><a href="/gallery-details#pre-orientation">View More</a></h4>
                                </div>
                                <div className='media_text'>
                                    <h2 className='subtitle'>pre orientation</h2>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} className='mb-15' data-aos="fade-up" data-aos-duration="3000">
                            <div className='media_card'>
                                <div className='media_img'>
                                    <Image src={galleryImg3} alt='gallery_img' fluid></Image>
                                </div>
                                <div className='img_overlay'>
                                    <h4><a href="/gallery-details#cpa">View More</a></h4>
                                </div>
                                <div className='media_text'>
                                    <h2 className='subtitle'>CPA</h2>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} className='mb-15' data-aos="fade-up" data-aos-duration="3000">
                            <div className='media_card'>
                                <div className='media_img'>
                                    <Image src={galleryImg4} alt='gallery_img' fluid></Image>
                                </div>
                                <div className='img_overlay'>
                                    <h4><a href="/gallery-details#launch">View More</a></h4>
                                </div>
                                <div className='media_text'>
                                    <h2 className='subtitle'>launch</h2>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} className='mb-15' data-aos="fade-up" data-aos-duration="3000">
                            <div className='media_card'>
                                <div className='media_img'>
                                    <Image src={galleryImg5} alt='gallery_img' fluid></Image>
                                </div>
                                <div className='img_overlay'>
                                    <h4><a href="/gallery-details#leap-workshop">View More</a></h4>
                                </div>
                                <div className='media_text'>
                                    <h2 className='subtitle'>leap workshop</h2>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} className='mb-15' data-aos="fade-up" data-aos-duration="3000">
                            <div className='media_card'>
                                <div className='media_img'>
                                    <Image src={galleryImg6} alt='gallery_img' fluid></Image>
                                </div>
                                <div className='img_overlay'>
                                    <h4><a href="/gallery-details#result">View More</a></h4>
                                </div>
                                <div className='media_text'>
                                    <h2 className='subtitle'>result</h2>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} className='mb-15' data-aos="fade-up" data-aos-duration="3000">
                            <div className='media_card'>
                                <div className='media_img'>
                                    <Image src={galleryImg7} alt='gallery_img' fluid></Image>
                                </div>
                                <div className='img_overlay'>
                                    <h4><a href="/gallery-details#felicitation">View More</a></h4>
                                </div>
                                <div className='media_text'>
                                    <h2 className='subtitle'>Felicitation</h2>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} className='mb-15' data-aos="fade-up" data-aos-duration="3000">
                            <div className='media_card'>
                                <div className='media_img'>
                                    <Image src={galleryImg8} alt='gallery_img' fluid></Image>
                                </div>
                                <div className='img_overlay'>
                                    <h4><a href="/gallery-details#guidance-session">View More</a></h4>
                                </div>
                                <div className='media_text'>
                                    <h2 className='subtitle'>guidance session</h2>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} className='mb-15' data-aos="fade-up" data-aos-duration="3000">
                            <div className='media_card'>
                                <div className='media_img'>
                                    <Image src={galleryImg9} alt='gallery_img' fluid></Image>
                                </div>
                                <div className='img_overlay'>
                                    <h4><a href="/gallery-details#science-visit">View More</a></h4>
                                </div>
                                <div className='media_text'>
                                    <h2 className='subtitle'>science visit</h2>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>      
    );
};

export default Gallery;