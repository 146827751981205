
export default [
    {image:require('../assets/images/alumni/1.jpg')},
    {image:require('../assets/images/alumni/2.jpg')},
    {image:require('../assets/images/alumni/3.jpg')},
    {image:require('../assets/images/alumni/4.jfif')},
    {image:require('../assets/images/alumni/5.jfif')},
    {image:require('../assets/images/alumni/6.jfif')},
    {image:require('../assets/images/alumni/7.jfif')},
    {image:require('../assets/images/alumni/8.jpg')},
    {image:require('../assets/images/alumni/9.jpg')},
    {image:require('../assets/images/alumni/10.jpg')},
    {image:require('../assets/images/alumni/11.jpg')},
    {image:require('../assets/images/alumni/12.jfif')},
    {image:require('../assets/images/alumni/13.jpg')},
    {image:require('../assets/images/alumni/14.jpg')},
    {image:require('../assets/images/alumni/15.jpg')},
    {image:require('../assets/images/alumni/16.jfif')},
    {image:require('../assets/images/alumni/17.jfif')},
    {image:require('../assets/images/alumni/18.jpg')},
    {image:require('../assets/images/alumni/19.jpg')},
    {image:require('../assets/images/alumni/20.jfif')},
    {image:require('../assets/images/alumni/21.jpg')},
    {image:require('../assets/images/alumni/22.jpg')},
    {image:require('../assets/images/alumni/23.jpg')},
    {image:require('../assets/images/alumni/24.jpg')},
    {image:require('../assets/images/alumni/25.jfif')},
    {image:require('../assets/images/alumni/26.jfif')},
    {image:require('../assets/images/alumni/27.jfif')},
    {image:require('../assets/images/alumni/28.jfif')},
    {image:require('../assets/images/alumni/29.jpg')},
    {image:require('../assets/images/alumni/30.jpg')},
    {image:require('../assets/images/alumni/31.jpg')},
    {image:require('../assets/images/alumni/32.jpg')},
    {image:require('../assets/images/alumni/33.jfif')},
    {image:require('../assets/images/alumni/34.jfif')},
    {image:require('../assets/images/alumni/35.jpg')},
    {image:require('../assets/images/alumni/36.jpg')},
    {image:require('../assets/images/alumni/37.jpg')},
    {image:require('../assets/images/alumni/38.jpg')},
    {image:require('../assets/images/alumni/39.jpg')},
    {image:require('../assets/images/alumni/40.jpg')},
    {image:require('../assets/images/alumni/41.jpg')},
    {image:require('../assets/images/alumni/42.jfif')},
    {image:require('../assets/images/alumni/43.jfif')},
    {image:require('../assets/images/alumni/44.jfif')},
    {image:require('../assets/images/alumni/45.jpg')},
    {image:require('../assets/images/alumni/46.jpg')},
    {image:require('../assets/images/alumni/47.jpg')},
    {image:require('../assets/images/alumni/48.jpg')},
    {image:require('../assets/images/alumni/49.jfif')},
    {image:require('../assets/images/alumni/50.jpg')},
    {image:require('../assets/images/alumni/51.jpg')},
    {image:require('../assets/images/alumni/52.jfif')},
    {image:require('../assets/images/alumni/53.jpg')},
    {image:require('../assets/images/alumni/54.jfif')},
    {image:require('../assets/images/alumni/55.jfif')},
    {image:require('../assets/images/alumni/56.jfif')},
    {image:require('../assets/images/alumni/57.jpg')},
    {image:require('../assets/images/alumni/58.jpg')},
    {image:require('../assets/images/alumni/59.jfif')},
    {image:require('../assets/images/alumni/60.jpg')},
    {image:require('../assets/images/alumni/61.jpg')},
    {image:require('../assets/images/alumni/62.jpg')},
    {image:require('../assets/images/alumni/63.jpg')},
    {image:require('../assets/images/alumni/64.jpg')},
    {image:require('../assets/images/alumni/65.jpg')},
    {image:require('../assets/images/alumni/66.jpg')},
    {image:require('../assets/images/alumni/67.jpg')},
    {image:require('../assets/images/alumni/68.jpg')},
    {image:require('../assets/images/alumni/69.jpg')},
    {image:require('../assets/images/alumni/70.jpg')},
    {image:require('../assets/images/alumni/71.jfif')},
    {image:require('../assets/images/alumni/72.jpg')},
    {image:require('../assets/images/alumni/73.jpg')},
    {image:require('../assets/images/alumni/74.jpg')},
    {image:require('../assets/images/alumni/75.jpg')},
    {image:require('../assets/images/alumni/76.jpg')},
    {image:require('../assets/images/alumni/77.jpg')},
    {image:require('../assets/images/alumni/78.jpg')},
    {image:require('../assets/images/alumni/79.jpg')},
    {image:require('../assets/images/alumni/80.jpg')},
    {image:require('../assets/images/alumni/81.jpg')},
    {image:require('../assets/images/alumni/82.jpg')},
    {image:require('../assets/images/alumni/83.jpg')},
    {image:require('../assets/images/alumni/84.jpg')},
    {image:require('../assets/images/alumni/85.jpg')},
    {image:require('../assets/images/alumni/86.jpg')},
    {image:require('../assets/images/alumni/87.jpg')},
    {image:require('../assets/images/alumni/88.jpg')}
]