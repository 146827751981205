import React from 'react';
import {Image, Button, Card} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import parse from 'html-react-parser';

const BlogItem = ({ category, image, title, detail, id }) => (
        <Card className='theme_card blog_card mb-4' data-aos="fade-up" data-aos-duration="3000">
            <Card.Body>
                <div className='inner'>
                    <div className='thumbnail'>
                        <a href={`/blog-details/${id}`}>
                            <Image src={image} alt='blog images' fluid></Image>
                        </a>
                    </div>
                    <div className='content'>
                        <h5 className='mb-3'>{title}</h5>
                        <h6 className='mb-4'><span className='category_text'>{category}</span></h6>
                        <div className='ellipsis_text'>{parse(detail)}</div>
                        <Button className='theme_btn' href={`/blog-details/${id}`}>Read More</Button>
                    </div>
                </div>
            </Card.Body>
        </Card> 
);

export default BlogItem;
