import React from 'react';
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';

// import bannerLeft from '../assets/images/banner-left-new.jpeg';
// import bannerRight from '../assets/images/banner-right.jpeg';
import center1 from '../assets/images/center-banner1.jpg';
import center2 from '../assets/images/center-banner2.jpg';
import center3 from '../assets/images/center-banner3.png';
import center4 from '../assets/images/center-banner4.png';



const Banner = props => {
  return (
    <>
      {/* <section className='banner_sec'>
        <Container className='mx-auto' fluid>
          <div className='justify-content-center'>
            <h2><i>shaping futures, igniting brilliance</i></h2>
          </div>
          <div className='mx-auto flex_component relative'>
            <div className='banner_slider'>
            <Carousel data-bs-theme="dark" id='carouselExampleAutoplaying'>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={center1}
                  alt="first slide"
                />               
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={center2}
                  alt="second slide"
                />               
              </Carousel.Item>
            </Carousel>
            </div>
            <div className='popout_images'>
          
            <Image src={bannerLeft} alt='left-img' className='swing left' fluid />
              <Image src={bannerRight} alt='right-img' className='swing right' fluid />
            </div>
          </div>
        </Container>
      </section> */}
      <section className='banner_sec'>
        <div className='banner_slider'>
          <Carousel data-bs-theme="dark" id='carouselExampleAutoplaying'>
              <Carousel.Item>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSfZXyQm_XnGGLZlQI9Ls-2CGYFokzMFINE82KajD5i-IKNeFw/viewform?embedded=true"
                   target="_blank">
                  <img
                    className="d-block w-100"
                    src={center3}
                    alt="first slide"
                  />     
                </a>          
              </Carousel.Item>
              <Carousel.Item>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSfZXyQm_XnGGLZlQI9Ls-2CGYFokzMFINE82KajD5i-IKNeFw/viewform?embedded=true" 
                target='_blank'>
                  <img
                    className="d-block w-100"
                    src={center4}
                    alt="second slide"
                  /> 
                </a>              
              </Carousel.Item>
              <Carousel.Item>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSfZXyQm_XnGGLZlQI9Ls-2CGYFokzMFINE82KajD5i-IKNeFw/viewform?embedded=true" 
                target='_blank'>
                  <img
                    className="d-block w-100"
                    src={center1}
                    alt="third slide"
                  />   
                </a>            
              </Carousel.Item>
              <Carousel.Item>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSfZXyQm_XnGGLZlQI9Ls-2CGYFokzMFINE82KajD5i-IKNeFw/viewform?embedded=true" 
                target='_blank'>
                <img
                  className="d-block w-100"
                  src={center2}
                  alt="forth slide"
                />         
                </a>      
              </Carousel.Item>
            </Carousel>
        </div>
      </section>      
    </>
  );
};

export default Banner;