import React, {useState, useEffect, useRef} from 'react';
import {Container} from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { NavLink } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import logo from '../assets/images/logo.png';

const Navigation = props => {
  const {current: dropdownId} = useRef("collapsible-nav-dropdown-" + (Math.random().toString(36)+'00000000000000000').slice(2, 7))
  const [scroll, setScroll] = useState(false);
  // const [show, setShow] = useState(false);

  // const showDropdown = (e)=>{
  //   setShow(!show);
  // }
  // const hideDropdown = e => {
  //     setShow(false);
  // }

  
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (   
      <Navbar collapseOnSelect expand="lg" className={scroll ? "sticky" : ""}>
      <Container>
        <Navbar.Brand href="/"><img src={logo} alt="logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="justify-content-end flex-grow-1">
            <Nav.Link href="/">Home</Nav.Link>
            {/* <Nav.Link href="/about">About</Nav.Link> */}
            <NavDropdown title="About" id={dropdownId} href='/about'>
              <NavDropdown.Item href="/about#about-us">About ICAD</NavDropdown.Item>
              <NavDropdown.Item href="/about#ceo-message">CEO Message</NavDropdown.Item>
              <NavDropdown.Item href="/about#about-mentors">About Mentors</NavDropdown.Item>
              <NavDropdown.Item href="/about#core-values">Core Values</NavDropdown.Item>
              <NavDropdown.Item href="/distinguishing-academics">Distinguishing Academics</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Courses" id="collapsible-nav-dropdown2">
              <NavDropdown.Item href="/engineering">Engineering</NavDropdown.Item>
              <NavDropdown.Item href="/medical">Medical</NavDropdown.Item>
              <NavDropdown.Item href="/foundation">Foundation</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/admission">Admission</Nav.Link>
            <NavDropdown title="Icadians" id="collapsible-nav-dropdown3">
              <NavDropdown.Item href="/icad-stars">ICAD Stars</NavDropdown.Item>
              <NavDropdown.Item href="/know-icadians">Know Icadians</NavDropdown.Item>
              <NavDropdown.Item href="/icad-alumni">ICAD Alumini</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/results">Results</Nav.Link>
            <NavDropdown title="Media" id="collapsible-nav-dropdown4">
              <NavDropdown.Item href="/news-paper">News Paper</NavDropdown.Item>
              <NavDropdown.Item href="/gallery">Photo Gallery</NavDropdown.Item>
              <NavDropdown.Item href="https://www.youtube.com/channel/UCm2p1O0DA9Xgv18pUe7Juew?feature=watch" target="_blank">Videos</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/study-centers">Study Centers</Nav.Link>
            {/* <NavDropdown title="Study Centers" id="collapsible-nav-dropdown5">
              <NavDropdown.Item href="/study-centers#nagpur_center">Nagpur</NavDropdown.Item>
              <NavDropdown.Item href="">Nagpur Residential</NavDropdown.Item>
              <NavDropdown.Item href="">Amravati</NavDropdown.Item>
              <NavDropdown.Item href="">Chandrapur</NavDropdown.Item>
              <NavDropdown.Item href="">Yawatmal</NavDropdown.Item>
            </NavDropdown> */}
            <Nav.Link href="/downloads">Downloads</Nav.Link>
            <Nav.Link href="/icad-experiance-center">Experience Center</Nav.Link>
            <NavDropdown title="Contact" id="collapsible-nav-dropdown6">
              <NavDropdown.Item href="/contact-us">Contact Us</NavDropdown.Item>
              <NavDropdown.Item href="/career">Work With Us</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;