import React, {useState} from 'react';
import { Container, Row, Col, Tabs, Tab} from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Navigation from '../components/Navigation';
import {SlideshowLightbox} from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';
import newsData from '../data/newsPaper';


const NewsPaper = props =>{
    const [key, setKey] = useState('1');
    const [subKey, setSubKey] = useState('2024');
    
    
    return(
        <main>
            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>News Paper</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>News Paper</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section  data-aos="fade-up" data-aos-duration="3000">
                <Container>                    
                    <Tabs
                        id="controlled-tab-example"
                        className="mt-50 horizontal_tabs"
                        justify
                        activeKey={key}
                        onSelect={(k) => setKey(k)}                        
                        >
                            {newsData.map((item, index) => (
                            <Tab eventKey={item.id} title={item.title} key={index}>
                                
                                    <Tabs
                                        id="uncontrolled-tab-example"
                                        className="mb-3 theme_tabs sub_tabs justify-content-center mt-5"
                                        activeKey={subKey}
                                        onSelect={(k) => setSubKey(k)}   
                                        >
                                            {item.news.map((n, i) => (
                                            <Tab eventKey={n.year} title={n.year} key={i}>
                                                <div className='gallery_grid newsPapers'>                                              
                                               
                                                   <SlideshowLightbox className='image_grid'>
                                                        {n.images.map((img, ind) => (
                                                        <img src={img} key={ind} class="img-fluid" />
                                                    ))} 
                                                </SlideshowLightbox>  
                                                </div>                                      
                                              
                                            </Tab>
                                             ))}                                                                          
                                    </Tabs>
                                
                            </Tab>
                            ))}
                        </Tabs>                        
                    </Container>
                </section>
        </main>
    );
};

export default NewsPaper;