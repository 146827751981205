import React, {useState} from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Navigation from '../components/Navigation';

import Breadcrumb from 'react-bootstrap/Breadcrumb';


import engResult1 from '../assets/images/results/eng-result-1.jpg';
import engResult2 from '../assets/images/results/eng-result-2.jpg';

const EngineeringResult = props => {
    return(
        <>
            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>Engineering Result</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/results">Results</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Engineering Result</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <h1 className='title mb-30'>Engineering Result 2024</h1>
                    <Image src={engResult1} alt="Engineering result 2024" className='d-block mx-auto' fluid></Image>
                </Container>
            </section>
            <section className='mt-50 bg_light_gray' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <Image src={engResult2} alt="Engineering result 2024" className='d-block mx-auto' fluid></Image>
                </Container>
            </section>
        </>
    );
};

export default EngineeringResult;