import React from 'react';
import {Container, Row, Col, Card, Image, CardBody, Tabs, Tab, Accordion} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import Navigation from '../components/Navigation';

import {SlideshowLightbox} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'

import email from '../assets/images/icons/email.png';
import location from '../assets/images/icons/location.png';
import phone from '../assets/images/icons/phone-call.png';
import centerImg from '../assets/images/ICAD_west.jpg';
import map from '../assets/images/location-map.png';
import result1 from '../assets/images/residential-result-1-2021.jpg';
import result2 from '../assets/images/residential-result-2-2021.jpg';
import result3 from '../assets/images/residential-result-3-2021.jpg';

import photograph from '../data/photograph';



const gallaryList = photograph.map((item) => <img src={item} alt='photograph' />)

const StudyCenters = props => {
    return(
        <main>
            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>Study Centers</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    {/* <Breadcrumb.Item href="">
                                        Library
                                    </Breadcrumb.Item> */}
                                    <Breadcrumb.Item active>Study Centers</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000" id="nagpur_center">
                <Container>               
                    <h1 className='title mb-3'>Study Centers</h1>
                    <Tabs
                        defaultActiveKey="nagpur"
                        id="justify-tab-example"
                        className="mb-3 horizontal_tabs"
                        justify
                        >
                        <Tab eventKey="nagpur" title="Nagpur">
                            <Card className='theme_card mt-5' data-aos="fade-up" data-aos-duration="3000">
                                <CardBody>
                                    <Row>
                                        <Col lg={6} md={8} sm={12}>
                                            <h4 className='primary_color mb-3'>Head Office and Training Center</h4>
                                            <ul className='contact_info_list'>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={location} alt='location icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Location Address:</h5>
                                                        <p className='mb-0'>21, Opposite Basket Ball Ground, Near Law College Square, Tilak Nagar
                                                        Nagpur - 440010.
                                                        Maharashtra State, INDIA</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={phone} alt='phone icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Contact NO.:</h5>
                                                        <p className='mb-0'>7887869155</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={email} alt='email icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Email Address:</h5>
                                                        <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={6} md={4} sm={12}>
                                            <Image src={centerImg} className='center image' rounded fluid></Image>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card className='theme_card mt-4' data-aos="fade-up" data-aos-duration="3000">
                                <CardBody>
                                    <Row>
                                        <Col lg={6} md={8} sm={12}>
                                            <h4 className='primary_color mb-3'>East Nagpur Training Center</h4>
                                            <ul className='contact_info_list'>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={location} alt='location icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Location Address:</h5>
                                                        <p className='mb-0'>Plot No. 389-A, Hanuman Nagar, Manewada Road, Nagpur - 440009,
                                                            Maharashtra State, India</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={phone} alt='phone icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Contact NO.:</h5>
                                                        <p className='mb-0'>7722085324</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={email} alt='email icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Email Address:</h5>
                                                        <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={6} md={4} sm={12}>
                                            <Image src={centerImg} className='center image' rounded fluid></Image>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>                          
                            <Card className='theme_card mt-4' data-aos="fade-up" data-aos-duration="3000">
                                <CardBody>
                                    <Row>
                                        <Col lg={6} md={8} sm={12}>
                                            <h4 className='primary_color mb-3'>Sadar Training Center</h4>
                                            <ul className='contact_info_list'>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={location} alt='location icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Location Address:</h5>
                                                        <p className='mb-0'>"Sunder Pride", Plot No. 25, Chitnavis Layout,
                                                        Byramji Town, Nagpur - 440014, Maharashtra State, INDIA</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={phone} alt='phone icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Contact NO.:</h5>
                                                        <p className='mb-0'>7722087091</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={email} alt='email icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Email Address:</h5>
                                                        <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={6} md={4} sm={12}>
                                            <Image src={centerImg} className='center image' rounded fluid></Image>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card className='theme_card mt-4' data-aos="fade-up" data-aos-duration="3000">
                                <CardBody>
                                    <Row>
                                        <Col lg={6} md={8} sm={12}>
                                            <h4 className='primary_color mb-3'>NEET Training Center</h4>
                                            <ul className='contact_info_list'>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={location} alt='location icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Location Address:</h5>
                                                        <p className='mb-0'>2nd Floor, Imperial Court, Nr Children Traffic Park, Khare Town, Dharampeth, Nagpur - 440010, Maharashtra State, INDIA</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={phone} alt='phone icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Contact NO.:</h5>
                                                        <p className='mb-0'>7887869155</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={email} alt='email icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Email Address:</h5>
                                                        <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={6} md={4} sm={12}>
                                            <Image src={centerImg} className='center image' rounded fluid></Image>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card className='theme_card mt-4' data-aos="fade-up" data-aos-duration="3000">
                                <CardBody>
                                    <Row>
                                        <Col lg={6} md={8} sm={12}>
                                            <h4 className='primary_color mb-3'>ICAD Chhatrapati Nagar Center</h4>
                                            <ul className='contact_info_list'>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={location} alt='location icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Location Address:</h5>
                                                        <p className='mb-0'>2nd Floor,
                                                        Chhatrapati Nagar Metro Station,
                                                        Chhatrapati Nagar,
                                                        Nagpur,
                                                        Maharashtra State, INDIA</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={phone} alt='phone icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Contact NO.:</h5>
                                                        <p className='mb-0'>7888060800</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={email} alt='email icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Email Address:</h5>
                                                        <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={6} md={4} sm={12}>
                                            <Image src={centerImg} className='center image' rounded fluid></Image>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Tab>
                        <Tab eventKey="nagpur-residential" title="Nagpur Residential">                        
                            <Tabs
                                defaultActiveKey="eight"
                                id="uncontrolled-tab-example"
                                className="mb-3 theme_tabs justify-content-center mt-5"
                                >
                                    <Tab eventKey="first" title="Why Residential">
                                        <h2 className='subtitle'>Why Residential</h2>
                                        <p>In essence, a residential program is a competitive preparatory institution, where students and teachers live and study together in a safe and secure campus environment. In other ways, however, you will find residential program is strictly unique. Living in a residential program leads to learning that is just as (or more) valuable than the education you get strictly in a classroom.</p>
                                        <div>
                                            <h5 className='underline'>Residential Program Will Develop Your Child</h5>
                                            <p>Residential Program has an outstanding tradition of academic excellence, built on an educational model that delivers diverse curricula, taught by highly qualified and exceptionally motivated teachers, in small classes that encourage meaningful interaction with the students. Ninety percent of residential students said in a recent survey that they felt challenged academically. Official figures back it up. Residential program students spend more than twice as many hours a week (35 to 56) on homework than their peers non residential program.</p>
                                        </div>
                                        <div>
                                            <h5 className='underline'>Learning Continues Beyond The Classroom</h5>
                                            <p>At residential program, learning extends beyond the classroom. Because staff and students live together, students benefit from countless "teachable moments" inside and outside of the classroom.</p>
                                        </div>
                                        <div>
                                            <h5 className='underline'>Residential Students Discover Self-reliance</h5>
                                            <p>It's never easy to leave the nest but one will learn how to cope with life and all its many high and low points in a community of your peers who are going through the same things you are. All of this is happening under the watchful eye of your teachers who are mentors, not baby-sitters. Residential environments can range in character from arts-focused to militarily-oriented, but almost always feature a student body composed of young people from a diversity of backgrounds. At residential program, students are empowered to achieve their true potential and enjoy a measure of real independence. Because they are not living at home, they are required to learn how to live with, trust, and respect their peers in an environment of "controlled freedom".</p>
                                        </div>
                                        <div>
                                            <h5 className='underline'>Residential Students Lead Exceptional Lives</h5>
                                            <p>Taking a lot of little steps towards maturity is one of the intangibles of going to residential program. You have to learn to get along with others because it is a community. You learn to be responsible for your actions because you are bound by an honor or discipline code of some kind. The lessons in life learned in residential program will lay a solid foundation for adulthood. Residential program also offer the priceless gift of preparing young people for future success. The academic rigor and the training in negotiating the responsibilities of independence set residential students on a path to prosperity-professionally, socially, and culturally. For Parents: This roughly translates to increased maturity, greater self - sufficiency and superior preparation for competitive world.</p>
                                        </div>
                                        <div>
                                            <h5 className='underline'>Discipline</h5>
                                            <p>The foremost function that is performed by a residential program is providing discipline and a sense of punctuality. Residential Program function on strict timetables and children are required to be punctual for all classes including academic and extracurricular ones.</p>
                                        </div>
                                        <div>
                                            <h5 className='underline'>Teacher Guidance</h5>
                                            <p>Residential Program have limited number of children in hostel room and each class room. This enables teachers to be able to concentrate on each individual student and any student who is lagging behind can be given the appropriate guidance. Moreover, students can also interact with the teachers and approaching them is easy as they are available on campus throughout the day.</p>
                                        </div>
                                        <div>
                                            <h5 className='underline'>Fewer Disctractions</h5>
                                            <p>Residential Program offer fewer distractions to the children as they are kept well away from the life of the urban areas. Residential Program are generally situated outside the city or in a remote place and students do not have the opportunity to involve themselves in any harmful activities or habits. Due to fewer distractions children will be able to concentrate more on studies and other activities.</p>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="second" title="Courses">
                                        <h2 className='subtitle'>Courses</h2>
                                        <div>
                                            <h4 className='underline mb-15'>2 Year JEE Residential Program (For XI Moving):</h4>
                                            <div>
                                                <h5 className='mb-0'>Excel - JEE</h5>
                                                <p>EXCEL - JEE is a 2 years program after X. It covers XI-XII Boards, MHT-CET, JEE Main, JEE Advanced & other engineering entrance exams.</p>
                                            </div><hr/>
                                            <h4 className='underline mb-15'>2 Year Nedical Residential Program (For XI Moving):</h4>
                                            <div>
                                                <h5 className='mb-0'>Excel - Medical</h5>
                                                <p>EXCEL - MEDICAL is a 2 years program after X. It covers XI-XII, Board Exam and Medical Entrance Exam i.e NEET.</p>
                                            </div><hr/>
                                            <h4 className='underline mb-15'>1 Year JEE Residential Program (For XII Moving):</h4>
                                            <div>
                                                <h5 className='mb-0'>Excel - JEE</h5>
                                                <p>EXCEL - JEE is a 1 year program after XI. It covers XI-XII Boards, MHT-CET, JEE Main, JEE Advanced & other engineering entrance exams.</p>
                                            </div><hr/>
                                            <h4 className='underline mb-15'>1 Year Medical Residential Program (For XII Moving):</h4>
                                            <div>
                                                <h5 className='mb-0'>Excel - Medical</h5>
                                                <p>EXCEL - MEDICAL is a 1 year program after XI. It covers XI-XII, Board Exam and Medical Entrance Exam i.e NEET.</p>
                                            </div>
                                            <Card className=''>
                                                <Card.Body>
                                                    <ul className='download_list'>
                                                        <li><a href='https://www.icadiit.com/downloads/2%20Yr%20JEE-NEET-Foundation%20ICAD-BROCHURE-2023.pdf' target='_blank'>Information Brochure</a></li>
                                                    </ul>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="third" title="Residential Facility">
                                        <h2 className='subtitle'>Residential Facility</h2>
                                        <Card className='theme_card'>
                                            <Card.Body>
                                            <iframe width="100%" height="400" src="https://www.youtube.com/embed/3DKg_0mfMZc?si=dJVYi2hF0BjgCxWd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                            </Card.Body>
                                        </Card>
                                        <Card className='mt-5'>
                                            <Card.Body>
                                                <Accordion className='theme_accordion' defaultActiveKey="0" flush>
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>Distinguish ICAD Residential</Accordion.Header>
                                                        <Accordion.Body>
                                                            <ul className='theme_list'>
                                                                <li>A green environment, refreshing, warming, clean, airy & oxygen rich place to study</li>
                                                                <li>Natural elements provide inspiration, visual pleasure & relief from disturbances or hustle & bustle life</li>	
                                                                <li>Residential campus is environmentally friendly technologies to significantly cool the houses in summer & warm them in winter</li>                                                                    
                                                                <li>Window system incorporating energy efficient glass which reflect infrared light from the sun & also it will have mosquito net shuttles</li>                                                                    
                                                                <li>Residential Campus will be encircled by wall, indoor & outdoor play areas & one entry gate for safety measure</li>
                                                            </ul>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="1">
                                                        <Accordion.Header>Utilities</Accordion.Header>
                                                        <Accordion.Body>
                                                            <ul className='theme_list'>
                                                                <li>All in One Suite Room at Ensaara Metropark.</li>
                                                                <li>Each wings of residential building will feature of 7 floors with Suite Bed (Bed, Cupboard, Study Table & Chair)</li>	
                                                                <li>24/7 hot & cold water supply</li>                                                                    
                                                                <li>Reliable & uninterrupted power supply</li>                                                                    
                                                                <li>Generator power backup for common area’s of residential campus</li>
                                                                <li>Anti-skid verified tiles in washroom, geyser & exhaust fan</li>
                                                                <li>Ample light & fan for healthy atmosphere</li>
                                                                <li>Elevator with AFD (Auto shift of elevator to next door during power failure)</li>
                                                            </ul>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="2">
                                                        <Accordion.Header>Amenities</Accordion.Header>
                                                        <Accordion.Body>
                                                            <ul className='theme_list'>
                                                                <li>International standard basketball, volleyball, cricket turf with flood lights.</li>
                                                                <li>Biodiversity park - 3 acres green park in residential campus will showcase the nature, fresh unpolluted air & landscaped garden of varying sizes and designs will feature benches, pergolas and shade - giving canopies</li>	
                                                                <li>Indoor playrooms for carom boards, chess, table tennis</li>                                                                    
                                                                <li>Badminton court for students inside residential campus</li>                                                                    
                                                                <li>Doctor facility & separate nursing / sick room for boys & girls</li>
                                                            </ul>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="3">
                                                        <Accordion.Header>Facilities</Accordion.Header>
                                                        <Accordion.Body>
                                                            <ul className='theme_list'>
                                                                <li>Room Cleaning by commercial vacuum cleaner</li>
                                                                <li>Purified drinking water</li>	
                                                                <li>Washing Area which consist of commercial washing machine</li>                                                                    
                                                                <li>CCTV Surveillance</li>
                                                            </ul>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="4">
                                                        <Accordion.Header>Residential Staff</Accordion.Header>
                                                        <Accordion.Body>
                                                            <ul className='theme_list'>
                                                                <li>Male & female warden</li>
                                                                <li>Security guards</li>	
                                                                <li>Supervisor</li>
                                                            </ul>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="5">
                                                        <Accordion.Header>Food</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p>A daily food schedules are made under the guidance of highly qualified dietician for healthy diet. Nevertheless, creating a daily food schedule is easy to do & which follow our planned steps too good to go.</p>
                                                            <p>Fine Dine area for food facility with one slot arrangements of 150+ students</p>
                                                            <ul className='theme_list'>
                                                                <li>Hot & Cold Milk</li>
                                                                <li>Healthy Breakfast along with butter, breads & eggs</li>	
                                                                <li>Lunch / Energetic second meal of the day</li>
                                                                <li>Hi-Tea</li>
                                                                <li>Delicious Dinner (my favourite recipes can be found in the art of great cooking with your instant pot)</li>
                                                                <li>Special Feast on Sunday</li>
                                                                <li>Seasonal & festival theme-based food menu</li>
                                                            </ul>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="6">
                                                        <Accordion.Header>Class Room / Study Area</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p>A minute walk from Residential campus (40 meters)</p>
                                                            <ul className='theme_list'>
                                                                <li>Well-furnished AC Classrooms</li>
                                                                <li>Digital boards</li>	
                                                                <li>Biometric attendance</li>
                                                                <li>Specific area for doubt clearing</li>
                                                                <li>5000 sq. feet. pleasant garden</li>
                                                                <li>Separate toilets units for boys & girls</li>
                                                                <li>Pantry area for serving breakfast & evening snacks</li>
                                                            </ul>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="7">
                                                        <Accordion.Header>Cultural Event</Accordion.Header>
                                                        <Accordion.Body>
                                                            <ul className='theme_list'>
                                                                <li>Unity in diversity Celebration like Diwali, Ganesh Puja Holi, Christmas, Eid & many more</li>
                                                                <li>Movie time, Sports Events & Educational Tour</li>	
                                                                <li>Yearly Picnic with fun & learning adventure</li>
                                                            </ul>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    <Tab eventKey="fourth" title="Photograph">
                                        <h2 className='subtitle'>Photograph</h2> 
                                        <SlideshowLightbox className='image_grid photographs'>
                                            {gallaryList}
                                        </SlideshowLightbox>
                                    </Tab>
                                    <Tab eventKey="fifth" title="Result">
                                        <h2 className='subtitle'>Residential Result 2021</h2>
                                        <div>
                                            <Image src={result1} alt="residential result" fluid></Image>
                                        </div><br/><hr/><br/>
                                        <div>
                                            <Image src={result2} alt="residential result" fluid></Image>
                                        </div><br/><hr/><br/>
                                        <div>
                                            <Image src={result3} alt="residential result" fluid></Image>
                                        </div>                                        
                                    </Tab>
                                    <Tab eventKey="sixth" title="Download">
                                        <h2 className='subtitle'>Download</h2>
                                        <div>
                                            <h5 className=''>Brochure</h5>                                                
                                            <Card className=''>
                                                <Card.Body>
                                                    <ul className='download_list'>
                                                        <li><a href='https://www.icadiit.com/downloads/2%20Yr%20JEE-NEET-Foundation%20ICAD-BROCHURE-2023.pdf'>Information Brochure</a></li>
                                                    </ul>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="seventh" title="Location">
                                        <h2 className='subtitle'>Location Map (Residential)</h2>
                                        <Image src={map} alt="map image" style={{maxHeight:'600px',margin:'0 auto', display:'block'}}fluid></Image>
                                        <div className='mt-5'><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=ICAD%20School%20of%20Learning%20(Residential%20Campus)%20Ensaara%20Metropark,%20Mauza-Pipla,%20Nagpur%20-%20440034%20.%20Maharashtra%20State,%20India+(ICAD%20Residential%20Campus)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps systems</a></iframe></div>

                                    </Tab>
                                    <Tab eventKey="eight" title="Contact">
                                        <h2 className='subtitle'>Contact</h2>
                                        <Card className='theme_card' data-aos="fade-up" data-aos-duration="3000">
                                            <CardBody>
                                                <Row>
                                                    <Col lg={6} md={8} sm={12}>
                                                        <h4 className='primary_color mb-3'>Residential Campus & Training Center</h4>
                                                        <ul className='contact_info_list'>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={location} alt='location icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Location Address:</h5>
                                                                    <p className='mb-0'>ICAD School of Learning (Residential Campus)
                                                                    Ensaara Metropark, Mauza-Pipla,
                                                                    Nagpur - 440034,
                                                                    Maharashtra State, India</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={phone} alt='phone icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Contact NO.:</h5>
                                                                    <p className='mb-0'>8530666340</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={email} alt='email icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Email Address:</h5>
                                                                    <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </Col>
                                                    <Col lg={6} md={4} sm={12}>
                                                        <Image src={centerImg} className='center image' rounded fluid></Image>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Tab>
                                </Tabs>
                        </Tab>
                        <Tab eventKey="amravati" title="Amravati">
                            <Card className='theme_card mt-5' data-aos="fade-up" data-aos-duration="3000">
                                <CardBody>
                                    <Row>
                                        <Col lg={6} md={8} sm={12}>
                                            <h4 className='primary_color mb-3'>ICAD Training Center - Amravati</h4>
                                            <ul className='contact_info_list'>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={location} alt='location icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Location Address:</h5>
                                                        <p className='mb-0'>Bhende Business Plaza, Near Hotel Rangoli, Khaparde Garden,
                                                        Opposite Railway Station Gate, Amravati - 444601,
                                                        Maharashtra State, INDIA</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={phone} alt='phone icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Contact NO.:</h5>
                                                        <p className='mb-0'>9823125131</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={email} alt='email icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Email Address:</h5>
                                                        <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={6} md={4} sm={12}>
                                            <Image src={centerImg} className='center image' rounded fluid></Image>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Tab>
                        <Tab eventKey="chandrapur" title="Chandrapur">
                            <Card className='theme_card mt-5' data-aos="fade-up" data-aos-duration="3000">
                                <CardBody>
                                    <Row>
                                        <Col lg={6} md={8} sm={12}>
                                            <h4 className='primary_color mb-3'>ICAD Training Center - Chandrapur</h4>
                                            <ul className='contact_info_list'>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={location} alt='location icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Location Address:</h5>
                                                        <p className='mb-0'>2nd Floor, Akbar Tower, Janta College Square, Nagpur Road, Chandrapur - 442401, Maharashtra State, INDIA</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={phone} alt='phone icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Contact NO.:</h5>
                                                        <p className='mb-0'>8055036222</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={email} alt='email icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Email Address:</h5>
                                                        <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={6} md={4} sm={12}>
                                            <Image src={centerImg} className='center image' rounded fluid></Image>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Tab>
                        <Tab eventKey="yawatmal" title="Yavatmal">
                            <Card className='theme_card mt-5' data-aos="fade-up" data-aos-duration="3000">
                                <CardBody>
                                    <Row>
                                        <Col lg={6} md={8} sm={12}>
                                            <h4 className='primary_color mb-3'>ICAD Training Center - Yavatmal</h4>
                                            <ul className='contact_info_list'>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={location} alt='location icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Location Address:</h5>
                                                        <p className='mb-0'>Ratan Imperial,
                                                        2nd Floor, Mahadev Mandir Road, Yavatmal - 445001,
                                                        Maharashtra State, INDIA</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={phone} alt='phone icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Contact NO.:</h5>
                                                        <p className='mb-0'>8055036222</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={email} alt='email icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Email Address:</h5>
                                                        <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={6} md={4} sm={12}>
                                            <Image src={centerImg} className='center image' rounded fluid></Image>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Tab>
                        <Tab eventKey="nashik" title="Nashik">
                            <Card className='theme_card mt-5' data-aos="fade-up" data-aos-duration="3000">
                                <CardBody>
                                    <Row>
                                        <Col lg={6} md={8} sm={12}>
                                            <h4 className='primary_color mb-3'>ICAD Training Center - Nashik</h4>
                                            <ul className='contact_info_list'>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={location} alt='location icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Location Address:</h5>
                                                        <p className='mb-0'>3rd Floor, Uttam Tower, Canada Corner, Sharanpur Road, Besides St. Andrews Church, Nashik - 422006, Maharashtra State, INDIA</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={phone} alt='phone icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Contact NO.:</h5>
                                                        <p className='mb-0'>8956429822</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={email} alt='email icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Email Address:</h5>
                                                        <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={6} md={4} sm={12}>
                                            <Image src={centerImg} className='center image' rounded fluid></Image>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Tab>
                        <Tab eventKey="chhatrapati" title="Chhatrapati Sambhaji Nagar">
                            <Card className='theme_card mt-5' data-aos="fade-up" data-aos-duration="3000">
                                <CardBody>
                                    <Row>
                                        <Col lg={6} md={8} sm={12}>
                                            <h4 className='primary_color mb-3'>ICAD Training Center - Chhatrapati Sambhaji Nagar</h4>
                                            <ul className='contact_info_list'>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={location} alt='location icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Location Address:</h5>
                                                        <p className='mb-0'>1st Floor, Hari Leela Complex, Mondha Naka Signal, Mondha Road, Chhatrapati Sambhaji Nagar - 431003
                                                        Maharashtra State, INDIA</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={phone} alt='phone icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Contact NO.:</h5>
                                                        <p className='mb-0'>8956413430</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={email} alt='email icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Email Address:</h5>
                                                        <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={6} md={4} sm={12}>
                                            <Image src={centerImg} className='center image' rounded fluid></Image>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Tab>
                        <Tab eventKey="nanded" title="Nanded">
                            <Card className='theme_card mt-5' data-aos="fade-up" data-aos-duration="3000">
                                <CardBody>
                                    <Row>
                                        <Col lg={6} md={8} sm={12}>
                                            <h4 className='primary_color mb-3'>ICAD Training Center - Nanded</h4>
                                            <ul className='contact_info_list'>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={location} alt='location icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Location Address:</h5>
                                                        <p className='mb-0'>Tirumala Heights, Tilak Nagar Stop, Near Shri Salasar tiles Bhagya Nagar Road, Nanded, Maharashtra State, INDIA</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={phone} alt='phone icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Contact NO.:</h5>
                                                        <p className='mb-0'>8956413455</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='icon_div'>
                                                        <Image src={email} alt='email icon'></Image>
                                                    </div>
                                                    <div className='details_content'>
                                                        <h5 className='mb-0'>Email Address:</h5>
                                                        <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={6} md={4} sm={12}>
                                            <Image src={centerImg} className='center image' rounded fluid></Image>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Tab>
                    </Tabs>                    
                </Container>
            </section>
        </main>
    );
};

export default StudyCenters;


