import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Card, ButtonGroup, Button} from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Navigation from '../components/Navigation';
import {useParams} from "react-router-dom"

import nonAcademicData from '../data/nonAcademicData';

const NonAcademicJobDescription = props => {
    const {nonAcademicItemId} = useParams()
    const thisItem = nonAcademicData.find(item => item.id === nonAcademicItemId)
    console.log(thisItem);
    return(
        <main>
             <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1 className='titlw'>job description</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/career">Career</Breadcrumb.Item>
                                    <Breadcrumb.Item active>job description</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <h2 className='title mb-30'>Job Description</h2>
                    <Row>
                        <Col sm={12}>
                            <Card className='theme_card detailTopCard mb-4'>
                                <Card.Body className='d-flex justify-content-between'>
                                    <div>
                                        <h3 className='mb-1'>{thisItem.position}</h3>
                                        <h5 className='mb-1'><span>Role:</span> {thisItem.role}</h5>
                                        <p className='location mb-0'><span>Location:</span> {thisItem.location}</p>
                                    </div>
                                    <div className=''>
                                        <Button className='theme_btn'>Apply</Button>
                                    </div>                                    
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} className='mb-3'>
                            <p className='mb-0 bold underline'>Job Description</p>
                            <ul>
                                <li>Admission counseling to prospective students / parents and convincing / converting them to final admission.</li>
                                <li>Face of Institution - Creating a goodwill and brand in the minds of students and parent by talking and sharing information with promoting our admission plans.</li>
                                <li>Tele-calling - Creating and Maintaining prospective students database; taking follow ups; finding means to reach right students through calling, mailing etc.</li>
                            </ul>
                        </Col>
                        <Col sm={12}>
                            <p className='mb-0 bold underline'>Candidate Profile Required</p>
                            <ul>
                                <li>Admission counseling to prospective students / parents and convincing / converting them to final admission.</li>
                                <li>Face of Institution - Creating a goodwill and brand in the minds of students and parent by talking and sharing information with promoting our admission plans.</li>
                                <li>Tele-calling - Creating and Maintaining prospective students database; taking follow ups; finding means to reach right students through calling, mailing etc.</li>
                            </ul>
                        </Col>
                        <Col sm={12}>
                            <p>To apply for this position, send your resume to <a href="mailto:hr.ho@icadjee.com">hr.ho@icadjee.com</a> or click on apply now</p>
                        </Col>
                    </Row>
                   
                </Container>
            </section>
        </main>
    );
};

export default NonAcademicJobDescription;