export default [
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0001.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0002.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0003.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0004.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0005.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0006.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0007.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0008.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0009.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0010.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0011.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0012.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0013.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0014.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0015.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0016.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0017.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0018.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0019.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0020.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0021.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0022.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0023.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0024.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0025.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0026.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0027.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0028.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0029.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0030.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0031.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0032.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0033.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0034.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0035.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0036.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0037.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0038.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0039.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0040.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0041.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0042.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0043.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0044.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0045.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0046.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0047.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0048.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0049.jpg')},
    { pageImg: require('../assets/images/documents/glimpse-of-digital-classes/glimpse-of-digital-classes_pages-to-jpg-0050.jpg')}
]