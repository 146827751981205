import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Image from 'react-bootstrap/Image';
import Navigation from '../components/Navigation';
import icadiansData from '../data/knowIcadians';

const KnowIcadians = props => {
    const icadiansItem = icadiansData.map((person, index) => {
        return(
            <Col xl={4} lg={6} md={6} sm={12} className='mb-4' key={index}>
                <Card className='theme_card student_card'>
                    <div className='d-flex'>
                        <div>
                            <Image src={person.photo} alt="student image"></Image>
                        </div>
                        <div className='content_div'>
                            <h1 className='title'>{person.name}</h1>
                            <p>{person.profile}</p>
                            <Button className="theme_btn" href={`/icadians-details/${person.id}`}>Know More</Button>
                        </div>
                    </div>
                </Card>
            </Col>
        );
    });
    return(
        <main>
            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>Know Icadians</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Know Icadians</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <h1 className='title mb-30'>Know Icadians</h1>
                    <Row>
                        {icadiansItem}
                    </Row>
                </Container>
            </section>
        </main>
    );
};

export default KnowIcadians;