import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import Navigation from '../components/Navigation';

const TermsAndConditions = props => {
    return(
        <main>
            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>Terms & Conditions</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Terms & Conditions</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <h1 className="title mb-30">Terms & Conditions</h1>
                    <ul className='theme_list'>
                        <li>You can confirm your seat by paying registration charges as prescribed in your offer letter.</li>
                        <li>Registration charges is nonrefundable but adjustable (at your name only) at other Centers of ICAD.</li>
                        <li>Kindly submit 2 passport size photographs along with student Aadhar Card photocopy at the time of registration.</li>
                        <li>Installments Post dated cheque (PDC) to be submitted at the time of admission.</li>
                        <li>Amount to be paid strictly as per prescribed installments patterns mentioned in offer letter.</li>
                        <li>ICAD reserves the right of admission.</li>
                        <li>By submitting our webform, you agree to receive promotional calls on the number shared, and such calls and SMS would be coming from a third-party platform.</li>
                    </ul>
                </Container>
            </section>
        </main>
    );
};

export default TermsAndConditions;