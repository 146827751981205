import React, {useState, useRef, useEffect} from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Navigation from '../components/Navigation';
import Carousel from 'react-bootstrap/Carousel';
import HTMLFlipBook from "react-pageflip";
import Modal from '../components/Modal';

import classroomIcon from '../assets/images/icons/classroom.png';
import practiceIcon from '../assets/images/icons/practice.png';
import testingIcon from '../assets/images/icons/testing.png';
import breaksIcon from '../assets/images/icons/breaks.png';
import rankIcon from '../assets/images/icons/rank.png';
import finalIcon from '../assets/images/icons/final.png';
import resultIcon from '../assets/images/icons/celebration.png';
import centerImg1 from '../assets/images/center-img1.jpg';
import centerImg2 from '../assets/images/center-img2.jpg';
import centerImg3 from '../assets/images/center-img3.jpg';
import playBtn from '../assets/images/icons/play-btn.png';
import studentTrust from '../assets/images/student-trust.jpg';

import capsuleData from '../data/commandCapsulePdf';
import digitalData from '../data/digitalClassesPdf';

//thumb images
import commandThumb from '../assets/images/command-caps-thumb.png';
import digitalThumb from '../assets/images/digital-thumb.jpg';
import appThumb from '../assets/images/online-app-thumb.png';
import rankBoostThumb from '../assets/images/rank-booster-thumb.png';
import hooksThumb from '../assets/images/hooks-law-thumb.jpg';
import hessThumb from '../assets/images/hess-law-thumb.jpg';
import geometryThumb from '../assets/images/geometry-thumb.jpg';
import conceptThumb from '../assets/images/concept-thumb.jpeg';

const ExperienceCenter = props => {

    // pds split functionality
    const book = useRef();
    // const [page, setPage] = useState(0);
    // const [totalPage, setTotalPage] = useState(0);
    const [fullscreen, setFullscreen] = useState(true);  
 
        const PageCover = React.forwardRef((props, ref) => {
            return (
              <div className="page page-cover" ref={ref} data-density="hard">
                <div className="page-content">
                  <h2>{props.children}</h2>
                </div>
              </div>
            );
          });
        const Page = React.forwardRef((props, ref) => {
            return (
              <div className="page" ref={ref}>
                <div className="page-content">
                  <h2 className="page-header">{props.number}</h2>
                  <div className="page-image"></div>
                  <div className="page-text">{props.children}</div>
                  {/* <div className="page-footer">{props.number + 1}</div> */}
                </div>
              </div>
            );
          });
    
          function nextButtonClick () {
            book.current.pageFlip().flipNext();
            };
            function prevButtonClick () {
                book.current.pageFlip().flipPrev();
            }; 
    
////////////////  

    const [isOpen, setIsOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalBody, setModalBody] = useState('');
    const [modalSize, setModalSize] = useState('lg');

    const capsuleHeader = () => {
        return(
            <div className='pdf_header'>
                <div className='flex-grow-1'>
                    <h1 className='mb-0 title'>Command Capsule</h1>
                </div>
                <div className='pdf_buttons'>
                    <Button className="theme_btn mr-3" onClick={prevButtonClick}>
                        Prev page
                    </Button>  
                    <Button className="theme_btn" onClick={nextButtonClick}>
                    Next page
                    </Button>               
                </div>
            </div>
        );
    };

    const glimpseHeader = () => {
        return(
            <div className='pdf_header'>
                <div className='flex-grow-1'>
                    <h1 className='mb-0 title'>Glimpse of Digital Classes</h1>
                </div>
                <div className='pdf_buttons'>
                    <Button className="theme_btn mr-3" onClick={prevButtonClick}>
                        Prev page
                    </Button>  
                    <Button className="theme_btn" onClick={nextButtonClick}>
                    Next page
                    </Button>                            
                </div>
            </div>
        );
    };

    const capsuleBody = () => {
        return(
            <HTMLFlipBook width={550}
                height={733}
                size="stretch"
                minWidth={315}
                maxWidth={1000}
                minHeight={400}
                maxHeight={1533}
                maxShadowOpacity={0.5}
                showCover={true}
                mobileScrollSupport={true}
                style={{ margin: "0 auto" }}
                // onFlip={onFlip}
                className="pdf_book"
                ref={book}
            >
              
                {/* <PageCover><Image src={capsuelPage1} fluid></Image></PageCover> */}
                {capsuleData.map((img, ind) => (
                    <Page key={ind}><Image src={img.pageImg} className='pdf_img' fluid></Image></Page>
                ))} 
                <PageCover>THE END</PageCover>

            </HTMLFlipBook>  
        );
    };

    const glimpseBody = () => {
        return(
            <HTMLFlipBook width={550}
            height={733}
            size="stretch"
            minWidth={315}
            maxWidth={1000}
            minHeight={400}
            maxHeight={1533}
            maxShadowOpacity={0.5}
            showCover={true}
            mobileScrollSupport={true}
                // onFlip={onFlip}
                className="pdf_book"
                ref={book}
            >
              
                {/* <PageCover><Image src={capsuelPage1} fluid></Image></PageCover> */}
                {digitalData.map((img, ind) => (
                    <Page key={ind}><Image src={img.pageImg} className='pdf_img' fluid></Image></Page>
                ))} 
                <PageCover>THE END</PageCover>

            </HTMLFlipBook> 
        )
    }

    const capsVideoBody = () => {
        return(
            <iframe width="100%" height="500" src="https://www.youtube.com/embed/Sset4aa-2qI?si=7Jo0Kqao-eGv7lxJ" title=" ICAD |  𝐑𝐞𝐯𝐨𝐥𝐮𝐭𝐢𝐨𝐧𝐢𝐳𝐞 𝐘𝐨𝐮𝐫 𝐋𝐞𝐚𝐫𝐧𝐢𝐧𝐠 𝐰𝐢𝐭𝐡 𝐈𝐂𝐀𝐃'𝐬 𝐂𝐨𝐦𝐦𝐚𝐧𝐝 𝐂𝐚𝐩𝐬𝐮𝐥𝐞𝐬!" frameborder="0" allow='autoplay' allowfullscreen></iframe>
        );
    };

    const appVideoBody = () => {
        return(
            <iframe width="100%" height="500" src="https://www.youtube.com/embed/V5-C9TXS4Q8?si=S7H4qJm8xVX00x86" title=" ICAD INNOVATION - ICAD ONLINE APP" frameborder="0" allow='autoplay' allowfullscreen></iframe>
        );
    };
    const boosterVideoBody = () => {
        return(
            <iframe width="100%" height="500" src="https://www.youtube.com/embed/cbZgPQY_rks?si=KZuYLAUL5hFZmGZ9" title="ICAD | AI-Assisted Rank Booster Test Series" frameborder="0" allow='autoplay' allowfullscreen></iframe>
        );
    };
    const hooksVideoBody = () => {
        return(
            <iframe width="100%" height="500" src="https://www.youtube.com/embed/x5AVfrPt0WY?si=eRSo34ma6h5OH61F" title="ICAD JEE/NEET Digital Classes.. A Curtain Raiser !!!" frameborder="0" allow='autoplay' allowfullscreen></iframe>
        );
    };
    const hessVideoBody = () => {
        return(
            <iframe width="100%" height="500" src="https://www.youtube.com/embed/XqmhKya_XSM?si=-XsHxFd-SULZzTTb" title="ICAD JEE/NEET Digital Classes.. A Curtain Raiser !!!" frameborder="0" allow='autoplay' allowfullscreen></iframe>
        );
    };
    const geometryVideoBody = () => {
        return(
            <iframe width="100%" height="500" src="https://www.youtube.com/embed/K1leAlgd95s?si=IsMxUCnVhUkXCG6z" title="ICAD JEE/NEET Digital Classes.. A Curtain Raiser !!!" frameborder="0" allow='autoplay' allowfullscreen></iframe>
        );
    };
    const conceptVideoBody = () => {
        return(
            <iframe width="100%" height="500" src="https://www.youtube.com/embed/JYVSPIbb6rc?si=k7YqOuFe81SXfhOW" title="ICAD | Welcome to another exciting episode of 𝐈𝐂𝐀𝐃: '𝐏𝐡𝐲𝐬𝐢𝐜𝐬 𝐌𝐚𝐝𝐞 𝐄𝐚𝐬𝐲!'" frameborder="0" allow='autoplay' allowfullscreen></iframe>
        );
    };

    const openModal = (title, body, size) => {
        setModalTitle(title);
        setModalBody(body);
        setModalSize(size);
        setIsOpen(true);
      };
    
      const closeModal = () => {
        setIsOpen(false);
        setModalTitle('');
        setModalBody('');
      };
    
    return(
        <>
            <Helmet>
                <title>Experience Center - ICAD Study Center</title>
                <meta name="description" content="Get info about ICAD experiance." />
                <meta name="keywords" content="JEE preparation simplified, NEET coaching with AI tools, digital learning tools for competitive exams, ICAD Command Capsules, AI rank booster for JEE/NEET, personalized performance reports for students, immersive digital classes for JEE, real-life application videos in science, concept videos for JEE/NEET preparation, sample test papers for JEE/NEET, best JEE coaching in Nagpur, online performance tracking for students, AI-based exam preparation tools, simplified study materials for competitive exams" />
            </Helmet>

            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>ICAD's Experience Center</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>                        
                                    <Breadcrumb.Item active>ICAD's Experience Center</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            
            <section className='mt-50' data-aos-delay="150" data-aos="fade-up" data-aos-duration="3000">
                <Container>    
                    <h1 className='title text-center'>ICAD’s Experience Centre : Where INNOVATION meets EDUCATION</h1>                
                    <Row className='top_padding'>
                        <Col sm={4}>
                            <Image src={centerImg1} alt="experiance image 1" className="centerImg" fluid></Image>
                        </Col>
                        <Col sm={4}>
                            <Image src={centerImg2} alt="experiance image 1" className="centerImg minus_margin" fluid></Image>
                        </Col>
                        <Col sm={4}>
                            <Image src={centerImg3} alt="experiance image 1" className="centerImg" fluid></Image>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col sm={12} className='text-center'>
                            <h1 className='title'>Experience the Future of Learning at ICAD</h1>
                            <p>Welcome to the ICAD Experience Centre – a space where innovation meets education. Explore our unique learning tools designed to simplify JEE, NEET, and foundation-level subjects for students. At ICAD, we blend advanced technology, personalized learning, and expert guidance to give students the ultimate edge. Explore our Command Capsules, Digital Classes, AI-driven tools, and more to experience cutting-edge education that transforms your academic journey.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50 bg_light_gray' data-aos-delay="150" data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <div>                        
                        <h1 className='title mb-0'>ICAD Experience Center: To make Journey of ICADians awesome.</h1>
                        <p>ICAD Experience Center: To make Journey of ICADians awesome. </p>
                    </div>
                    <hr/>
                    <Row>
                        <Col md={6} sm={12}>
                            <div className='service'>
                                <div className='icon'>
                                    <Image src={classroomIcon} alt="icon"></Image>
                                </div>
                                <div className='content'>
                                    <h5 className='mb-1'>Classroom Experience</h5>
                                    <p>Pre-reading through most simplified Command Capsule</p>
                                    <p>Awesome classroom experience with Digital Classes - real life examples, concept videos, recap by most experienced Faculty.</p>
                                </div>                                        
                            </div>
                            <div className='service'>
                                <div className='icon'>
                                    <Image src={practiceIcon} alt="icon"></Image>
                                </div>
                                <div className='content'>
                                    <h5 className='mb-1'>Practice Experience</h5>
                                    <p>Step 1 to 6 bifurcation of questions, Daily Monitored Solving in Classrooms, Solving Check by Faculty members</p>
                                </div>                                        
                            </div>
                            <div className='service'>
                                <div className='icon'>
                                    <Image src={testingIcon} alt="icon"></Image>
                                </div>
                                <div className='content'>
                                    <h5 className='mb-1'>Testing Experience</h5>
                                    <p>Most scientifically designed papers, Instant Results across all centers, Instant communication to students and parents of result</p>
                                </div>                                        
                            </div>
                        </Col>
                        <Col md={6} sm={12}>
                            <div className='service'>
                                <div className='icon'>
                                    <Image src={breaksIcon} alt="icon"></Image>
                                </div>
                                <div className='content'>
                                    <h5 className='mb-1'>Breaks</h5>
                                    <p>Celebrations, Garba Event, Alumni Sessions</p>
                                </div>                                        
                            </div>
                            <div className='service'>
                                <div className='icon'>
                                    <Image src={rankIcon} alt="icon"></Image>
                                </div>
                                <div className='content'>
                                    <h5 className='mb-1'>Rank Booster Program</h5>
                                    <p>After Syllabus Completion</p>
                                </div>                                        
                            </div>
                            <div className='service'>
                                <div className='icon'>
                                    <Image src={finalIcon} alt="icon"></Image>
                                </div>
                                <div className='content'>
                                    <h5 className='mb-1'>Final Testing Sessions</h5>
                                    <p>for JEE(Main), Advanced, NEET, CET etc. Rank Booster Test Series.</p>
                                </div>                                        
                            </div>                            
                        </Col>
                        <Col sm={12}>
                            <div className='service justify-content-center mb-0'>
                                <div className='icon'>
                                    <Image src={resultIcon} alt="icon"></Image>
                                </div>
                                <div className='content'>
                                    <h5 className='mb-1'>Result Celebration</h5>
                                    <p>Result Celebration</p>
                                </div>                                        
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>  
            <section className='mt-50 video_sec'>
                <Carousel data-bs-theme="dark" id='subVideoSecCarousel' interval={null}>
                    <Carousel.Item>
                        <Container>
                            <Row>
                                <Col lg={6} md={12} className='mb-3'>
                                    <div data-aos-delay="150" data-aos="fade-up" data-aos-duration="800">
                                        <h2>Command Capsules – The Power of Simplicity </h2>
                                        <p>Discover our exclusive Command Capsules – a breakthrough learning resource designed to simplify complex concepts.</p>
                                        <p>Key Features :</p>
                                        <ul className='check_list'>
                                            <li>Simplified Theoretical Concepts: Our concise theory explanations help students grasp difficult topics quickly, ensuring clarity and deep understanding.</li>
                                            <li>Solved Numericals: Step-by-step solutions to real exam problems build confidence and mastery.</li>
                                            <li>Quick Recap Questions: Revise key concepts in minutes with our strategically placed quick-recap questions.</li>
                                            <li>Suggested Questions: A set of well-curated questions to practice and test your understanding after every topic.</li>
                                            <li>Difficulty-Level Practice: Progress from Step 1 to Step 6 with difficulty-level wise practice questions designed to build skills incrementally.</li>
                                        </ul>                                
                                    </div>
                                </Col>
                                <Col lg={6} md={12} className='text-center mb-3'>
                                    <div className="video_gallery" data-aos-delay="150" data-aos="fade-left" data-aos-duration="800">
                                        <div className='thumbnail'>
                                            <Image src={commandThumb} alt="video thumb" fluid></Image>
                                            <a onClick={() => openModal('', capsVideoBody, 'lg')} className='video_play_btn'>
                                                <Image src={playBtn} alt="play button" fluid></Image>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='cta_btn'>
                                        <Button className='theme_btn' onClick={() => openModal(capsuleHeader, capsuleBody, 'fullscreen')}>Explore Our Command Capsules</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container>
                            <Row>
                                <Col lg={6} md={12} className='text-center mb-3'>
                                    <div className="video_gallery" data-aos-delay="150" data-aos="fade-left" data-aos-duration="800">
                                        <div className='thumbnail'>
                                            <Image src={digitalThumb} alt="video thumb" fluid></Image>
                                        </div>
                                    </div>
                                    <div className='cta_btn'>
                                        <Button className='theme_btn' onClick={() => openModal(glimpseHeader, glimpseBody, 'fullscreen')}>Watch a Glimpse of Our Digital Classes</Button>
                                    </div>
                                </Col>
                                <Col lg={6} md={12} className='mb-3'>
                                    <div data-aos-delay="150" data-aos="fade-up" data-aos-duration="800">
                                        <h2>Digital Class – A Mesmerizing Learning Experience</h2>
                                        <p>Step into the future of learning with our Digital Classes, which combine captivating visuals, animations, and simulations to create an immersive learning experience.</p>
                                        <p>Key Features :</p>
                                        <ul className='check_list'>
                                            <li>Stunning Visuals and Animations: Mesmerizing visuals and animations simplify complex physics, chemistry, mathematics and biology concepts.</li>
                                            <li>Interactive Simulations: Bring theories to life with interactive simulations that allow you to explore concepts dynamically.</li>
                                            <li>Discussion Prompts: Engage in thought-provoking questions during and after lessons to deepen your understanding.</li>
                                            <li>Faculty-Supervised Problem Solving: Get expert guidance as faculty members help students solve problems step-by-step during classes.</li>
                                        </ul>                                
                                    </div>
                                </Col>                                
                            </Row>
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container>
                            <Row>
                                <Col lg={6} md={12} className='mb-3'>
                                    <div data-aos-delay="150" data-aos="fade-up" data-aos-duration="800">
                                        <h2>ICAD Online App – Personalized Performance Reports</h2>
                                        <p>Track your academic progress effortlessly with the ICAD Online App, your personalized study companion.</p>
                                        <p>Key Features :</p>
                                        <ul className='check_list'>
                                            <li>Detailed Performance Reports: Get insights into your strengths and areas of improvement through comprehensive reports.</li>
                                            <li>AI-Driven Suggestions: Our AI tool identifies weak concepts and offers personalized hints and remedial questions for faster improvement.</li>
                                            <li>Track Tests and Assignments: Stay on top of your tests and assignments, tracking your scores and performance trends.</li>                                            
                                        </ul>                                
                                    </div>
                                </Col>
                                <Col lg={6} md={12} className='text-center mb-3'>
                                    <div className="video_gallery" data-aos-delay="150" data-aos="fade-left" data-aos-duration="800">
                                        <div className='thumbnail'>
                                            <Image src={appThumb} alt="video thumb" fluid></Image>
                                            <a onClick={() => openModal('', appVideoBody, 'lg')} className='video_play_btn'>
                                                <Image src={playBtn} alt="play button" fluid></Image>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='cta_btn'>
                                        <Button className='theme_btn'>Get Started with the ICAD Online App</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container>
                            <Row>
                                <Col lg={6} md={12} className='text-center mb-3'>
                                    <div className="video_gallery" data-aos-delay="150" data-aos="fade-left" data-aos-duration="800">
                                        <div className='thumbnail'>
                                            <Image src={rankBoostThumb} alt="video thumb" fluid></Image>
                                            <a onClick={() => openModal('', boosterVideoBody, 'lg')} className='video_play_btn'>
                                                <Image src={playBtn} alt="play button" fluid></Image>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='cta_btn'>
                                        <Button className='theme_btn'>Boost Your Rank with Our AI Series</Button>
                                    </div>
                                </Col>
                                <Col lg={6} md={12} className='mb-3'>
                                    <div data-aos-delay="150" data-aos="fade-up" data-aos-duration="800">
                                        <h2>AI Rank Booster Series – Maximize Your Potential</h2>
                                        <p>Enhance your performance with the AI Rank Booster Series, designed to give students an extra push before crucial exams.</p>
                                        <p>Key Features :</p>
                                        <ul className='check_list'>
                                            <li>AI-Based Analysis: Identify gaps in your preparation with AI-powered analytics that pinpoint weak areas.</li>
                                            <li>Personalised Study Plans: Get customized study suggestions to maximize your scores based on your current progress.</li>
                                            <li>Step-by-Step Practice: Progress through difficulty levels, ensuring no concept is left behind.</li>
                                        </ul>                                
                                    </div>
                                </Col>                                
                            </Row>
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container>
                            <Row>
                                <Col lg={6} md={12} className='mb-3'>
                                    <div data-aos-delay="150" data-aos="fade-up" data-aos-duration="800">
                                        <h2>Real-Life Application Videos</h2>
                                        <p>Bring science to life with our Real-Life Application Videos, where theoretical concepts are applied to everyday scenarios.</p>
                                        <p>Key Features :</p>
                                        <ul className='check_list'>
                                            <li>Hands-On Learning: Understand real-life applications of science in engineering, medicine, and everyday life.</li>
                                            <li>Conceptual Understanding: These videos help students visualize how theories translate into real-world solutions.</li>
                                            <li>Engaging and Interactive: Stay engaged with our clear, concise, and interactive videos that make learning fun.</li>                                           
                                        </ul>                                
                                    </div>
                                </Col>
                                <Col lg={6} md={12} className='text-center mb-3'>
                                    <div className="video_gallery multi" data-aos-delay="150" data-aos="fade-left" data-aos-duration="800">
                                        <Row>
                                            <div className='thumbnail col-md-6 col-sm-12 mb-3'>
                                                <Image src={hooksThumb} alt="video thumb" fluid></Image>
                                                <a onClick={() => openModal('', hooksVideoBody, 'lg')} className='video_play_btn'>
                                                    <Image src={playBtn} alt="play button" fluid></Image>
                                                </a>
                                            </div>
                                            <div className='thumbnail col-md-6 col-sm-12 mb-3'>
                                                <Image src={hessThumb} alt="video thumb" fluid></Image>
                                                <a onClick={() => openModal('', hessVideoBody, 'lg')} className='video_play_btn'>
                                                    <Image src={playBtn} alt="play button" fluid></Image>
                                                </a>
                                            </div>
                                            <div className='thumbnail col-md-6 col-sm-12 text-center mx-auto'>
                                                <Image src={geometryThumb} alt="video thumb" fluid></Image>
                                                <a onClick={() => openModal('', geometryVideoBody, 'lg')} className='video_play_btn'>
                                                    <Image src={playBtn} alt="play button" fluid></Image>
                                                </a>
                                            </div>
                                        </Row>
                                    </div>
                                    <div className='cta_btn'>
                                        <Button className='theme_btn'>Watch Our Real-Life Application Videos</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container>
                            <Row>
                                <Col lg={6} md={12} className='text-center mb-3'>
                                    <div className="video_gallery" data-aos-delay="150" data-aos="fade-left" data-aos-duration="800">
                                        <div className='thumbnail'>
                                            <Image src={conceptThumb} alt="video thumb" fluid></Image>
                                            <a onClick={() => openModal('', conceptVideoBody, 'lg')} className='video_play_btn'>
                                                <Image src={playBtn} alt="play button" fluid></Image>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='cta_btn'>
                                        <Button className='theme_btn'>Explore Our Concept Videos</Button>
                                    </div>
                                </Col>
                                <Col lg={6} md={12} className='mb-3'>
                                    <div data-aos-delay="150" data-aos="fade-up" data-aos-duration="800">
                                        <h2>Concept Videos – Simplified Learning on the Go</h2>
                                        <p>Explore  our Concept Videos for bite-sized, easy-to-understand explanations of tough topics.</p>
                                        <p>Key Features :</p>
                                        <ul className='check_list'>
                                            <li>Quick Explanations: Master key topics in minutes with short, focused concept videos.</li>
                                            <li>On-the-Go Learning: Access videos anytime, anywhere to strengthen your understanding of critical concepts.</li>
                                            <li>Visual and Audio Aids: These videos use a combination of visuals and audio to make learning easier.</li>
                                        </ul>                                
                                    </div>
                                </Col>                                
                            </Row>
                        </Container>
                    </Carousel.Item>
                </Carousel>
            </section> 
            <section className='mt-50 bg_light_gray custom_sec' data-aos-delay="150" data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <Row>
                        <Col lg={6} md={12} className='mb-3'>
                            <Image src={studentTrust} alt="why trust us image"  className='radius_img' fluid></Image>
                        </Col>
                        <Col lg={6} md={12}>
                            <h2>The ICAD Difference – Why Students Trust Us</h2>
                            <p>At ICAD, we go beyond traditional teaching methods to ensure that our students excel. From innovative resources like Command Capsules and Digital Classes to AI-powered tools, we offer a comprehensive learning experience that prepares students for the toughest exams with confidence.</p>
                            <Button className='theme_btn'>Appear for Entrance CPA</Button>
                        </Col>
                    </Row>
                </Container>              

            </section> 

            <Modal isOpen={isOpen} onClose={closeModal} title={modalTitle} body={modalBody} size={modalSize} />
        </>
    );
};

export default ExperienceCenter;