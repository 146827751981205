export default [
    require('../assets/images/photographs/photo1.jpg'),
    require('../assets/images/photographs/photo2.jpeg'),
    require('../assets/images/photographs/photo3.jpg'),
    require('../assets/images/photographs/photo4.jpg'),
    require('../assets/images/photographs/photo5.jpg'),
    require('../assets/images/photographs/photo6.jpg'),
    require('../assets/images/photographs/photo7.jpg'),
    require('../assets/images/photographs/photo8.jpg'),
    require('../assets/images/photographs/photo9.jpg'),
    require('../assets/images/photographs/photo10.jpg'),
    require('../assets/images/photographs/photo11.jpg'),
    require('../assets/images/photographs/photo12.jpg'),
    require('../assets/images/photographs/photo13.jpg'),
    require('../assets/images/photographs/photo14.jpg'),
    require('../assets/images/photographs/photo15.jpg'),
    require('../assets/images/photographs/photo16.jpg'),
    require('../assets/images/photographs/photo17.jpg'),
    require('../assets/images/photographs/photo18.jpeg'),
    require('../assets/images/photographs/photo19.jpg'),
    require('../assets/images/photographs/photo20.jpg'),
    require('../assets/images/photographs/photo21.jpeg'),
    require('../assets/images/photographs/photo22.jpg'),
    require('../assets/images/photographs/photo23.jpg'),
    require('../assets/images/photographs/photo24.jpeg'),
    require('../assets/images/photographs/photo25.jpg'),
    require('../assets/images/photographs/photo26.jpeg'),
    require('../assets/images/photographs/photo27.jpg'),
    require('../assets/images/photographs/photo28.jpg'),
    require('../assets/images/photographs/photo29.jpg'),
    require('../assets/images/photographs/photo30.jpeg'),
    require('../assets/images/photographs/photo31.jpeg'),
    require('../assets/images/photographs/photo32.jpg'),
    require('../assets/images/photographs/photo33.jpg'),
    require('../assets/images/photographs/photo34.jpeg'),
    require('../assets/images/photographs/photo35.jpg'),
    require('../assets/images/photographs/photo36.jpeg'),
    require('../assets/images/photographs/photo37.jpg'),
    require('../assets/images/photographs/photo38.jpg'),
    require('../assets/images/photographs/photo39.jpg'),
    require('../assets/images/photographs/photo40.jpeg')
];