import React from 'react';
import { Container, Row, Col, Card, Nav } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Image from 'react-bootstrap/Image';
import Tab from 'react-bootstrap/Tab';
import { Helmet } from 'react-helmet';

import ContactForm from '../components/ContactForm';


import Navigation from '../components/Navigation';

import email from '../assets/images/icons/email.png';
import location from '../assets/images/icons/location.png';
import phone from '../assets/images/icons/phone-call.png';
import centerImg from '../assets/images/ICAD_west.jpg';

const ContactUs = props => {    
    return(
        <>
            <Helmet>
                <title>Contact Us - ICAD Study Center</title>
                <meta name="description" content="Get in touch with us through this page." />
                <meta name="keywords" content="Contact ICAD Nagpur, best JEE coaching contact, NEET coaching inquiries" />
            </Helmet>

            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>Contact Us</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <div className='mb-5'>
                        <h2 className='title'>Get in Touch with ICAD</h2>
                        <p>Have questions about our programs or admissions process? Reach out to us directly using 
                        the contact information below or visit our Nagpur center.</p>
                    </div>
                    <h2 className='subtitle mb-3'>Contact Information</h2>
                    <div className='vertical_tabs'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col lg={5} md={12} sm={12} className='mb-mid'>                                        
                                    <Nav variant="pills" className="flex-column">
                                        <h2 className='subtitle mb-0'>Nagpur</h2>
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">ICAD Head Office and Training Center</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">ICAD Training Center - Nagpur (East)</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">ICAD Training Center - Nagpur (Sadar)</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="forth">NEET Training Center - Nagpur</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="fifth">ICAD Chhatrapati Nagar Center - Nagpur</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="sixth">Residential Campus & Training Centre</Nav.Link>
                                        </Nav.Item>
                                        <br/>
                                        <h2 className='subtitle mb-0'>amravati</h2>
                                        <Nav.Item>
                                            <Nav.Link eventKey="seventh">ICAD Corporate Center - Amravati</Nav.Link>
                                        </Nav.Item><br/>
                                        <h2 className='subtitle mb-0'>chandrapur</h2>
                                        <Nav.Item>
                                            <Nav.Link eventKey="eight">ICAD Corporate Center - Chandrapur</Nav.Link>
                                        </Nav.Item><br/>
                                        <h2 className='subtitle mb-0'>yavatmal</h2>
                                        <Nav.Item>
                                            <Nav.Link eventKey="nine">ICAD Corporate Center - Yavatmal</Nav.Link>
                                        </Nav.Item><br/>
                                        <h2 className='subtitle mb-0'>Nashik</h2>
                                        <Nav.Item>
                                            <Nav.Link eventKey="ten">ICAD Training Center - Nashik</Nav.Link>
                                        </Nav.Item><br/>
                                        <h2 className='subtitle mb-0'>Chhatrapati Sambhaji Nagar</h2>
                                        <Nav.Item>
                                            <Nav.Link eventKey="eleven">ICAD Training Center - Chhatrapati Sambhaji Nagar</Nav.Link>
                                        </Nav.Item><br/>
                                        <h2 className='subtitle mb-0'>Nanded</h2>
                                        <Nav.Item>
                                            <Nav.Link eventKey="twelve">ICAD Training Center - Nanded</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col lg={7} md={12} sm={12}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <div>
                                                <h2 className='subtitle mb-15'>ICAD Head Office and Training Center:</h2>
                                                <Row>
                                                    <Col sm={8}>
                                                        <ul className='contact_info_list'>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={location} alt='location icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Location Address:</h5>
                                                                    <p className='mb-0'>21, Opposite Basket Ball Ground, Near Law College Square, Tilak Nagar
                                                                    Nagpur - 440010.
                                                                    Maharashtra State, INDIA</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={phone} alt='phone icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Contact NO.:</h5>
                                                                    <p className='mb-0'>7887869155</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={email} alt='email icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Email Address:</h5>
                                                                    <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </Col>
                                                    <Col sm={4}>
                                                        <Image src={centerImg} className='center image' rounded fluid></Image>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <div>
                                                <h2 className='subtitle mb-15'>ICAD Training Center - Nagpur (East):</h2>
                                                <Row>
                                                    <Col sm={8}>
                                                        <ul className='contact_info_list'>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={location} alt='location icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Location Address:</h5>
                                                                    <p className='mb-0'>Plot No. 389-A, Hanuman Nagar, Manewada Road, Nagpur - 440009,
                                                                        Maharashtra State, India</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={phone} alt='phone icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Contact NO.:</h5>
                                                                    <p className='mb-0'>7722085324</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={email} alt='email icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Email Address:</h5>
                                                                    <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </Col>
                                                    <Col sm={4}>
                                                        <Image src={centerImg} className='center image' rounded fluid></Image>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                        <div>
                                            <h2 className='subtitle mb-15'>ICAD Training Center - Nagpur (Sadar):</h2>
                                            <Row>
                                                <Col sm={8}>
                                                    <ul className='contact_info_list'>
                                                        <li>
                                                            <div className='icon_div'>
                                                                <Image src={location} alt='location icon'></Image>
                                                            </div>
                                                            <div className='details_content'>
                                                                <h5 className='mb-0'>Location Address:</h5>
                                                                <p className='mb-0'>"Sunder Pride", Plot No. 25, Chitnavis Layout,
                                                                Byramji Town, Nagpur - 440014, Maharashtra State, INDIA</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='icon_div'>
                                                                <Image src={phone} alt='phone icon'></Image>
                                                            </div>
                                                            <div className='details_content'>
                                                                <h5 className='mb-0'>Contact NO.:</h5>
                                                                <p className='mb-0'>7722087091</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='icon_div'>
                                                                <Image src={email} alt='email icon'></Image>
                                                            </div>
                                                            <div className='details_content'>
                                                                <h5 className='mb-0'>Email Address:</h5>
                                                                <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </Col>
                                                <Col sm={4}>
                                                    <Image src={centerImg} className='center image' rounded fluid></Image>
                                                </Col>
                                            </Row>
                                        </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="forth">
                                            <div>
                                                <h2 className='subtitle mb-15'>NEET Training Center - Nagpur:</h2>
                                                <Row>
                                                    <Col sm={8}>
                                                        <ul className='contact_info_list'>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={location} alt='location icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Location Address:</h5>
                                                                    <p className='mb-0'>2nd Floor, Imperial Court, Nr Children Traffic Park, Khare Town, Dharampeth, Nagpur - 440010, Maharashtra State, INDIA</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={phone} alt='phone icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Contact NO.:</h5>
                                                                    <p className='mb-0'>7887869155</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={email} alt='email icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Email Address:</h5>
                                                                    <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </Col>
                                                    <Col sm={4}>
                                                        <Image src={centerImg} className='center image' rounded fluid></Image>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="fifth">
                                            <div>
                                                <h2 className='subtitle mb-15'>ICAD Chhatrapati Nagar Center - Nagpur:</h2>
                                                <Row>
                                                    <Col sm={8}>
                                                        <ul className='contact_info_list'>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={location} alt='location icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Location Address:</h5>
                                                                    <p className='mb-0'>2nd Floor,
                                                                    Chhatrapati Nagar Metro Station,
                                                                    Chhatrapati Nagar,
                                                                    Nagpur,
                                                                    Maharashtra State, INDIA</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={phone} alt='phone icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Contact NO.:</h5>
                                                                    <p className='mb-0'>7888060800</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={email} alt='email icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Email Address:</h5>
                                                                    <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </Col>
                                                    <Col sm={4}>
                                                        <Image src={centerImg} className='center image' rounded fluid></Image>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="sixth">
                                            <div>
                                                <h2 className='subtitle mb-15'>Residential Campus & Training Centre:</h2>
                                                <Row>
                                                    <Col sm={8}>
                                                    <ul className='contact_info_list'>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={location} alt='location icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Location Address:</h5>
                                                                    <p className='mb-0'>ICAD School of Learning (Residential Campus)
                                                                    Ensaara Metropark, Mauza-Pipla,
                                                                    Nagpur - 440034,
                                                                    Maharashtra State, India</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={phone} alt='phone icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Contact NO.:</h5>
                                                                    <p className='mb-0'>8530666340</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={email} alt='email icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Email Address:</h5>
                                                                    <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </Col>
                                                    <Col sm={4}>
                                                        <Image src={centerImg} className='center image' rounded fluid></Image>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="seventh">
                                            <div>
                                                <h2 className='subtitle mb-15'>ICAD Training Center - Amravati:</h2>
                                                <Row>
                                                    <Col sm={8}>
                                                        <ul className='contact_info_list'>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={location} alt='location icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Location Address:</h5>
                                                                    <p className='mb-0'>Bhende Business Plaza, Near Hotel Rangoli, Khaparde Garden,
                                                                    Opposite Railway Station Gate, Amravati - 444601,
                                                                    Maharashtra State, INDIA</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={phone} alt='phone icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Contact NO.:</h5>
                                                                    <p className='mb-0'>9823125131</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={email} alt='email icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Email Address:</h5>
                                                                    <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </Col>
                                                    <Col sm={4}>
                                                        <Image src={centerImg} className='center image' rounded fluid></Image>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="eight">
                                            <div>
                                                <h2 className='subtitle mb-15'>ICAD Training Center - Chandrapur:</h2>
                                                <Row>
                                                    <Col sm={8}>
                                                        <ul className='contact_info_list'>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={location} alt='location icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Location Address:</h5>
                                                                    <p className='mb-0'>2nd Floor, Akbar Tower, Janta College Square, Nagpur Road, Chandrapur - 442401, Maharashtra State, INDIA</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={phone} alt='phone icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Contact NO.:</h5>
                                                                    <p className='mb-0'>8055036222</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={email} alt='email icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Email Address:</h5>
                                                                    <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </Col>
                                                    <Col sm={4}>
                                                        <Image src={centerImg} className='center image' rounded fluid></Image>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="nine">
                                            <div>
                                                <h2 className='subtitle mb-15'>ICAD Training Center - Yavatmal:</h2>
                                                <Row>
                                                    <Col sm={8}>
                                                        <ul className='contact_info_list'>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={location} alt='location icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Location Address:</h5>
                                                                    <p className='mb-0'>Ratan Imperial,
                                                                    2nd Floor, Mahadev Mandir Road, Yavatmal - 445001,
                                                                    Maharashtra State, INDIA</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={phone} alt='phone icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Contact NO.:</h5>
                                                                    <p className='mb-0'>8055036222</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={email} alt='email icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Email Address:</h5>
                                                                    <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        </Col>
                                                    <Col sm={4}>
                                                        <Image src={centerImg} className='center image' rounded fluid></Image>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="ten">
                                            <div>
                                                <h2 className='subtitle mb-15'>ICAD Training Center - Nashik:</h2>
                                                <Row>
                                                    <Col sm={8}>
                                                        <ul className='contact_info_list'>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={location} alt='location icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Location Address:</h5>
                                                                    <p className='mb-0'>3rd Floor, Uttam Tower, Canada Corner, Sharanpur Road, Besides St. Andrews Church, Nashik - 422006, Maharashtra State, INDIA</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={phone} alt='phone icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Contact NO.:</h5>
                                                                    <p className='mb-0'>8956429822</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={email} alt='email icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Email Address:</h5>
                                                                    <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        </Col>
                                                    <Col sm={4}>
                                                        <Image src={centerImg} className='center image' rounded fluid></Image>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="eleven">
                                            <div>
                                                <h2 className='subtitle mb-15'>ICAD Training Center - Chhatrapati Sambhaji Nagar:</h2>
                                                <Row>
                                                    <Col sm={8}>
                                                        <ul className='contact_info_list'>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={location} alt='location icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Location Address:</h5>
                                                                    <p className='mb-0'>1st Floor, Hari Leela Complex, Mondha Naka Signal, Mondha Road, Chhatrapati Sambhaji Nagar - 431003
                                                                    Maharashtra State, INDIA</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={phone} alt='phone icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Contact NO.:</h5>
                                                                    <p className='mb-0'>8956413430</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={email} alt='email icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Email Address:</h5>
                                                                    <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        </Col>
                                                    <Col sm={4}>
                                                        <Image src={centerImg} className='center image' rounded fluid></Image>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="twelve">
                                            <div>
                                                <h2 className='subtitle mb-15'>ICAD Training Center - Nanded:</h2>
                                                <Row>
                                                    <Col sm={8}>
                                                        <ul className='contact_info_list'>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={location} alt='location icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Location Address:</h5>
                                                                    <p className='mb-0'>Tirumala Heights, Tilak Nagar Stop, Near Shri Salasar tiles Bhagya Nagar Road, Nanded, Maharashtra State, INDIA</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={phone} alt='phone icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Contact NO.:</h5>
                                                                    <p className='mb-0'>8956413455</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={email} alt='email icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Email Address:</h5>
                                                                    <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        </Col>
                                                    <Col sm={4}>
                                                        <Image src={centerImg} className='center image' rounded fluid></Image>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </Container>
            </section>
            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    {/* <h2 className='title mb-30'>get in touch</h2> */}
                    <Row>
                        <Col lg={6} md={12} className="mb-mid">
                            <h2 className='subtitle'>Visit Our Nagpur Center</h2>
                            <p>Feel free to visit our Nagpur center to learn more about our programs and meet our faculty.</p>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3721.1881084215706!2d79.058368!3d21.144911!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c05ddc7a71eb%3A0xa27a7df06f1d92c8!2sICAD!5e0!3m2!1sen!2sin!4v1430290770386" width="100%" height="500px" frameborder="0"></iframe>
                        </Col>
                        <Col lg={6} md={12}>
                            <h2 className='subtitle mb-4'>Send us a message</h2>
                            <Card className='theme_card'>
                                <Card.Body>
                                    <ContactForm/>
                                </Card.Body>
                            </Card>                                
                        </Col>                            
                    </Row>
                </Container>
            </section>
        </>      
    );
};

export default ContactUs;