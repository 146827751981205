import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import Navigation from '../components/Navigation';

const RefundAndCancellationPolicy = props => {
    return(
        <main>
            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>Refund & Cancellation Policy</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Refund & Cancellation Policy</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <h1 className="title mb-30">Refund & Cancellation Policy</h1>
                    <ul className='theme_list'>
                        <li>Registration charges is nonrefundable but adjustable (at your name only) at other Centers of ICAD.</li>
                        <li>If student cancel the admission Refund of Fees depends on the conditions and offer letter provided to the student.</li>
                    </ul>
                </Container>
            </section>
        </main>
    );
};

export default RefundAndCancellationPolicy;