import React from 'react';
import { Container, Row, Col, Card, ButtonGroup, Button} from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Navigation from '../components/Navigation';

import academicData from '../data/academicData';
import nonAcademicData from '../data/nonAcademicData';



const Career = props => {
    const academicItems = academicData.map(item => {
        return(
            <Col lg={4} sm={6} xs={12} key={item.id} className="mb-3">
                <Card className='theme_card career_card'>
                    <Card.Body>
                        <h4>{item.position}</h4>
                        <h6>Location : {item.location}</h6>
                        <ButtonGroup className='float-end mt-2'>
                            <Button className="theme_btn" href={`/academic-job-description/${item.id}`}>Job Description</Button>
                            <Button className="theme_btn">Apply</Button>
                        </ButtonGroup>
                    </Card.Body>
                </Card>
            </Col>
        );
    });
    const nonAcademicItems = nonAcademicData.map(item => {
        return(
            <Col lg={4} sm={6} xs={12} key={item.id} className="mb-3">
                <Card className='theme_card career_card'>
                    <Card.Body>
                        <h4>{item.position}</h4>
                        <h6>Location : {item.location}</h6>
                        <ButtonGroup className='float-end mt-2'>
                            <Button className="theme_btn" href={`/non-academic-job-description/${item.id}`}>Job Description</Button>
                            <Button className="theme_btn">Apply</Button>
                        </ButtonGroup>
                    </Card.Body>
                </Card>
            </Col>
        );
    });
    return(
        <main>
            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>Work with Us</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>work with us</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50'>
                <Container>
                    <h2 className='title mb-30'>Work With Us</h2>                    
                    {/* <Row className='mb-3' data-aos="fade-up" data-aos-duration="3000">
                        <h4 className='mb-3'>Academic</h4>
                        {academicItems}
                    </Row> */}
                    <Row data-aos="fade-up" data-aos-duration="3000">
                        <h4 className='mb-3'>Non-Academic</h4>
                        {nonAcademicItems}
                    </Row>
                </Container>
            </section>
        </main>
    );
};

export default Career;