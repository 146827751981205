export default [
    {
        id:"1",
        title:"navbharat",
        news:[   
            {
                year:"2024",
                images:[
                    require('../assets/images/news/navbharat/navbharat1-24.jpeg'),
                    require('../assets/images/news/navbharat/navbharat2-24.jpg'),
                ]
            },
            {
                year:"2022",
                images:[
                    require('../assets/images/news/navbharat/navbharat1-22.jpeg'),
                    require('../assets/images/news/navbharat/navbharat2-22.jpg'),
                ]
            }
        ],        
    },
    {
        id:"2",
        title:"lokmat",
        news:[  
            {
                year:"2024",
                images:[
                    require('../assets/images/news/lokmat/lokmat-24.jpeg'),
                    require('../assets/images/news/lokmat/lokmat2-24.jpeg'),
                    require('../assets/images/news/lokmat/lokmat3-24.jpeg'),
                    require('../assets/images/news/lokmat/lokmat4-24.jpg'),
                    require('../assets/images/news/lokmat/lokmat6-24.jpg'),
                    require('../assets/images/news/lokmat/lokmat7-24.jpeg'),
                    require('../assets/images/news/lokmat/lokmat8-24.jpg'),
                    require('../assets/images/news/lokmat/lokmat9-24.jpg'),
                    require('../assets/images/news/lokmat/lokmat10-24.jpg')
                ]
            },
            {
                year:"2023",
                images:[
                    require('../assets/images/news/lokmat/lokmat1-23.jpeg')
                ]

            },                     
            {
                year:"2022",
                images:[
                    require('../assets/images/news/lokmat/lokmat1-22.jpeg'),
                    require('../assets/images/news/lokmat/lokmat2-22.jpeg'),
                    require('../assets/images/news/lokmat/lokmat3-22.jpg'),
                    require('../assets/images/news/lokmat/lokmat4-22.jpeg'),
                    require('../assets/images/news/lokmat/lokmat5-22.jpg'),
                    require('../assets/images/news/lokmat/lokmat6-22.jpg')
                ]

            }                              
        ],        
    },
    {
        id:"3",
        title:"dainik bhaskar",
        news:[  
            {
                year:"2024",
                images:[
                    require('../assets/images/news/dainik-bhaskar/dainik1-24.jpeg'),
                    require('../assets/images/news/dainik-bhaskar/dainik2-24.jpg')
                ]
            },                    
            {
                year:"2022",
                images:[
                    require('../assets/images/news/dainik-bhaskar/dainik1-22.jpg'),
                    require('../assets/images/news/dainik-bhaskar/dainik2-22.jpg')
                ]

            },                 
        ],        
    },
    {
        id:"4",
        title:"TheHitavada",
        news:[  
            {
                year:"2024",
                images:[
                    require('../assets/images/news/hitvada/hitvada1-24.jpeg'),
                    require('../assets/images/news/hitvada/hitvada2-24.jpg'),
                    require('../assets/images/news/hitvada/hitvada3-24.jpg'),
                    require('../assets/images/news/hitvada/hitvada4-24.jpg')
                ]
            },
            {
                year:"2023",
                images:[
                    require('../assets/images/news/hitvada/hitvada1-23.jpeg'),
                ]

            },                     
            {
                year:"2022",
                images:[
                    require('../assets/images/news/hitvada/hitvada1-22.jpg'),
                ]
            }                 
        ],        
    },
    {
        id:"5",
        title:"Tarun Bharat",
        news:[                              
            {
                year:"2022",
                images:[
                    require('../assets/images/news/tarun-bharat/tarun1-22.jpg'),
                ]

            }                
        ],        
    },
    {
        id:"6",
        title:"Navrashtra",
        news:[  
            {
                year:"2024",
                images:[
                    require('../assets/images/news/navrashtra/navrashtra1-24.jpg'),
                ]
            },                 
            {
                year:"2022",
                images:[
                    require('../assets/images/news/navrashtra/navrashtra1-22.jpg'),
                ]

            }                
        ],        
    },
    {
        id:"7",
        title:"Sakal",
        news:[  
            {
                year:"2024",
                images:[
                    require('../assets/images/news/sakal/sakal1-24.jpg'),
                ]
            }             
        ],        
    },
]