import React, {useState} from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { Helmet } from 'react-helmet';

import Navigation from '../components/Navigation';
import ContactForm from '../components/ContactForm';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import CourseImg from '../assets/images/course.jpg';

const Foundation = props => {
    const [key, setKey] = useState('home');
    return (
        <>
            <Helmet>
                <title>Foundation Program - ICAD Study Center</title>
                <meta name="description" content="Learn more about foundation program" />
                <meta name="keywords" content="JEE coaching Nagpur, NEET preparation Nagpur, foundation program for class 8 to 10, IIT coaching" />
            </Helmet>

            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>Foundation</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Foundation</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='mt-50'>
                <Container className=''>
                    <Row className=''>
                        <Col sm={8}>
                            <div className='left_panel'>
                                <Image src={CourseImg} alt='course-image' className='mb-15' />
                                <h1 className='title'>Foundation</h1>
                                <p>ICAD offers top class training courses which are designed & conceptualized based on simple structure, efficient testing, easy grasping & Student's capability.</p>
                                <p>ICAD's offering is much more than just a classroom training. It's truly a learning experience.</p>
                                <p className='mb-0'><b>For Details Contact:</b></p>
                                <p className='mb-0'>Master Foundation - 9355870236</p>
                                <p>Excel Foundation - 9355870236</p>
                            </div>
                            <div>
                            <Tabs
                            defaultActiveKey="first"
                            id="uncontrolled-tab-example"
                            className="mb-3 theme_tabs"
                            >
                                <Tab eventKey="first" title="Programs Offered">
                                    <div>
                                        <h2 className='subtitle underline mb-15'>Programs Offered</h2>
                                        <p>ICAD offers top class training courses which are designed & conceptualized based on simple structure, efficient testing & easy grasping.</p>
                                        <p>ICAD's offering is much more than just a classroom training. It's truly a learning experience.</p>
                                        <div className='mb-4'>
                                            <h2 className='subtitle mb-1'>Foundation - Excel Program</h2>
                                            <p className='mb-0 mb-1'><b>Foundation - Excel Program Covers</b></p>
                                            <ul className='theme_list'>
                                                <li>Physics Olympiads (NSEP, INPhO)</li>
                                                <li>Chemistry Olympiads (NSEC, INChO)</li>
                                                <li>Astronomy Olympiads (NSEA, INAO)</li>
                                                <li>Mathematics Olympiads (PRMO, RMO, INMO, IMO & AMTI)</li>
                                                <li>KVPY</li>
                                                <li>NTSE</li>
                                                <li>Engineering Entrance Exam (JEE)</li>
                                                <li>Medical Entrance Exam (NEET)</li>
                                            </ul>
                                        </div>
                                        <div>
                                            <h2 className='subtitle mb-1'>Foundation - Master Program</h2>
                                            <p className='mb-0 mb-1'><b>Foundation - Master Program Covers</b></p>
                                            <ul className='theme_list'>
                                                <li>School Syllabus- (CBSE/State) 8th,9th & 10th</li>
                                                <li>Subjects- Physics, Chemistry, Biology, Maths English, SST & Foundation of higher order Concepts</li>
                                                <li>Homi Bhabha Exams- 6th & 9th Std.</li>
                                                <li>MTSE Exams- 8th, 9th & 10th Std.</li>
                                                <li>NTSE Exam-10th Std.</li>
                                                <li>Pravinya / Pradnya Exams- 6th & 8th</li>
                                                <li>SOF Olympiads- Maths & Science-2nd Level, 5th to 10th Std.</li>
                                                <li>National & International Essay competitions.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="second" title="Excel Foundation">
                                    <div className='mb-30'>
                                        <h2 className='subtitle underline mb-15'>Academics - Excel Foundation</h2>
                                        <div>                                                
                                            <p className='mb-0 mb-1'>Objective: The basic objective of Excel-Foundation Training Program is to build strong Conceptual and Skilled Foundation for;</p>
                                            <ol>
                                                <li>Top Ranks in JEE and NEET and in the process.</li>
                                                <li>Create Olympiad Medal Winners</li>
                                                <li>Qualify most challenging competitive exams like KVPY, NMTC (AMTI), NTSE, etc.</li>                                                   
                                            </ol>
                                            <p className='mb-0'><b>A Strong Academic Foundation Leads to Great Academic Achievements..!!</b></p>
                                            <p>Every student is special in his own ways. Any student has his/her unique ability and skills to understand and reproduce what he learns. Some students have a strong academic hunger, which keeps them excited to learn something which is beyond their school curriculum. They are always eager to enhance their knowledge levels and are ready to do the required efforts. Normally, these are the students who excel in major competitive exams and secure best of the Universities/Colleges for their higher studies.</p>
                                            <p>In contrast to this, some students are unable to cope-up with the routine school syllabus and they need a strong external academic support to sail through. With proper guidance, grooming and mentoring, these students pick up the required skills and speed to achieve the desired success.</p>
                                        </div>
                                        <div>
                                            <p className='mb-0 mb-1'><b>Some of the significant achievement of ICADians from this program;</b></p>
                                            <ul className='theme_list'>
                                                <li>Top ranks in JEE (Main & Advanced)</li>
                                                <li>City Topper- JEE Main</li>
                                                <li>City Topper- JEE Advanced</li>
                                                <li>City Topper- NEET</li>
                                                <li>BITSAT Toppers</li>
                                                <li>Selection in MIT (Massachusetts Institute of Technology ,USA)</li>
                                                <li>6 Medals in International Science Olympiads</li>
                                                <li>Maharashtra Board Toppers (CBSE & State)</li>
                                                <li>35 ICADians Cleared KVPY-level-1</li>
                                                <li>21 ICADians Cleared KVPY-level-2</li>
                                                <li>54 ICADians cleared RMO (Regional Mathematics Olympiad)</li>
                                                <li>9 ICADians cleared INMO (Indian National Mathematics Olympiad)</li>
                                                <li>22 ICADians cleared AMTI (National Mathematics Talent Contest)</li>
                                                <li>10 ICADians cleared NSEA (Indian National Astronomy Olympiad)</li>
                                                <li>2 ICADians cleared INCho (Indian National Chemistry Olympiad)</li>
                                                <li>2 ICADians cleared INPho (Indian National Physics Olympiad)</li>
                                            </ul>
                                            <p className='mb-1'><b>ICADians from this program have consistently brought many laurels to the city & the Nation.</b></p>
                                            <p className='mb-1'><b>Subjects Covered -</b> Science & Mathematics.</p>
                                            <p><b>Schedule -</b> Monday to Saturday- 6.30 pm to 8.30 pm</p>
                                        </div>
                                    </div>                                       
                                </Tab>
                                <Tab eventKey="third" title="Master Foundation">
                                    <div className='mb-30'>
                                        <h2 className='subtitle underline mb-15'>Master Foundation</h2>
                                        <div>                                                
                                            <p className='mb-0 mb-1'>The basic objective of this program is to ensure a student's success in School & Board exams along with the competitive exams such as Homi Bhabha, Scholarship Exam & NTSE etc. A comprehensive coverage of School curriculum with the practical exposure along with a fair amount of coaching for the competitive exams, gives the student an additional advantage to excel in School and other exams.</p>
                                            <p>An excellent academic programme for 8th, 9th & 10th std. students from CBSE & State Board students.</p>
                                        </div>
                                        <div>
                                            <p className='mb-1'><b>Salient Features-</b></p>
                                            <ul className='theme_list'>
                                                <li>Comprehensive learning process for the students.</li>
                                                <li>Subjects covered- Maths, Science, English & SST. Medium of teaching would be English.</li>
                                                <li>Separate batches for CBSE & State Boards.</li>
                                                <li>Timings 5.30 PM to 8.00 PM (Monday to Saturday).</li>
                                                <li>Practical exposure to Science through AVISHKAR experiments inside the classrooms.</li>
                                                <li>Trained and highly qualified faculty team.</li>
                                                <li>Digital classrooms using Smart boards teaching aids.</li>
                                                <li>ICAD notes on latest Board syllabus for Maths , Science, English and SST.</li>
                                                <li>MCQ & case based type questions to master the methods for solving questions asked in competitive exams, with speed & accuracy.</li>
                                                <li>Foundation lectures every week covering higher order concepts covering syllabus of competitive exams.</li>
                                                <li>Separate ICAD book 'Mastership' for foundation syllabus.</li>
                                                <li>Regular testing, subjective as well as objective type.</li>
                                                <li>Regular Monthly Quiz.</li>
                                                <li>Daily Doubt clearing. All teachers available at Centre from 12.00 Noon to 5.00 PM for clearing doubts of students.</li>
                                                <li>Regular parents' meeting and Group counselling for progress assessment and remedial measure.</li>
                                                <li>Parent’s app (android) for information of Students performance & attendance.</li>
                                                <li>Three Test Series for 10th std. students.</li>
                                                <li>Regular sessions for all subjects by Board moderators for class X students for guidance about how to write Board paper to excel.</li>
                                                <li>Science Visit to VNIT to explore new avenues.</li>
                                                <li>Individual attention to every student.</li>
                                                <li>Strict discipline</li>
                                            </ul>
                                            <p>At every ICAD centre, limited batch of limited students per standard, per Board, makes this program an exclusive and special program.</p>
                                            <p>Admission to this program is based on the marks obtained in the previous year's School exam / LEAP Exam / CPA Exam.</p>
                                            <p><b>CRASH COURSE -</b> FOR 10TH MOVING STUDENTS</p>
                                            <p><b>TEST SERIES -</b> FOR 10TH STD. STUDENTS</p>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className="d-grid gap-2 mb-30">
                                <Button className='theme_btn' size="lg" href='#enquiry-form'>Enquire now</Button>
                                {/* <Button className='theme_btn' size="lg" href='/results'>Results</Button> */}
                            </div>
                            <Card className='mb-30 theme_card'>
                                <Card.Body>
                                    <h2 className='subtitle underline'>Other Courses</h2>
                                    <ul className='custom_list'>
                                        <li><a href='/engineering'>Engineering</a></li>
                                        <li><a href='/medical'>Medical</a></li>
                                    </ul>
                                </Card.Body>
                            </Card>
                            <Card id='enquiry-form' className='theme_card'>
                                <Card.Body>
                                    <h2 className='subtitle underline mb-15'>Enquiry Form For JEE</h2>
                                    <ContactForm/>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Foundation;
