import React from 'react';
import { Container, Row, Col, Card, Image} from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Navigation from '../components/Navigation';

import digital from '../assets/images/digital.png';
import digitalIcon from '../assets/images/icons/test.png';
import farsIcon from '../assets/images/icons/touch.png';
import connectIcon from '../assets/images/icons/parent.png';
import testingIcon from '../assets/images/icons/website.png';
import collegeIcon from '../assets/images/icons/graduating-student.png';

const IcadDigital = props => {
    return(
        <main>
            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>ICAD Digital</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>ICAD Digital</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <h1 className='title mb-30'>ICAD Digital</h1>
                    <Row className='bg_secondary align-items-center'>
                        <Col md={6} sm={12} className='bg_secondary align-items-center'>
                            <div className='digital_banner'>
                                <Image src={digital} alt='digital main image' fluid></Image>
                            </div>
                        </Col>
                        <Col md={6} sm={12} className='bg_secondary align-items-center'>
                            <div className='digital_tabs'>
                                <Card className='theme_card'>
                                    <Card.Body>
                                        <a href="">
                                            <Image src={digitalIcon} alt="digital icon" fluid></Image>
                                            <h5>ICAD Digital</h5>
                                        </a>
                                    </Card.Body>
                                </Card>
                                <Card className='theme_card'>
                                    <Card.Body>
                                        <a href="">
                                            <Image src={farsIcon} alt="digital icon" fluid></Image>
                                            <h5>FARS</h5>
                                        </a>
                                    </Card.Body>
                                </Card>
                                <Card className='theme_card'>
                                    <Card.Body>
                                        <a href="">
                                            <Image src={connectIcon} alt="digital icon" fluid></Image>
                                            <h5>Parent Connect</h5>
                                        </a>
                                    </Card.Body>
                                </Card>
                                <Card className='theme_card'>
                                    <Card.Body>
                                        <a href="">
                                            <Image src={testingIcon} alt="digital icon" fluid></Image>
                                            <h5>Testing</h5>
                                        </a>
                                    </Card.Body>
                                </Card>
                                <Card className='theme_card'>
                                    <Card.Body>
                                        <a href="">
                                            <Image src={collegeIcon} alt="digital icon" fluid></Image>
                                            <h5>College</h5>
                                        </a>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};

export default IcadDigital;