import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Helmet } from 'react-helmet';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

import Navigation from '../components/Navigation';

import FormModal from '../components/FormModal';



const Admission = props => {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <>
            <Helmet>
                <title>Admission - ICAD Study Center</title>
                <meta name="description" content="Get info about admission process through this page" />
                <meta name="keywords" content="ICAD admission process, JEE coaching admission Nagpur, scholarships for IIT JEE, CPA. LEAP" />
            </Helmet>

            <div className='header_wrapper'>
                <Navigation />
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>Admission</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Admission</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50'>
                <Container>
                    <Row className='mb-30'>
                        <Col sm={12}>
                            <div className='mb-30'>
                                <h1 className='title'>Join ICAD – The First Step Towards Your Dream Career</h1>
                                <p>Ready to join the ranks of successful JEE and NEET aspirants? Start your journey with ICAD 
                                by enrolling in one of our programs. Here’s how to apply and what to expect from the 
                                admissions process.</p>
                            </div>
                            <Card className='theme_card'>
                                <Card.Body>
                                    <Accordion className='theme_accordion' defaultActiveKey="0" flush>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>How to Apply</Accordion.Header>
                                            <Accordion.Body>
                                                {/* <p>CPA is a unique test which verifies your analytical skills acquired at high school level. CPA is designed in such a way that the conceptual understanding of the subject knowledge is finely tested. The popularity of CPA is in its simple form, which tests the complex concepts in the most lucid and straight – forward way. A high performance in CPA truly reflects the conceptual clarity of the student.</p> */}
                                                <p className='mb-1'>Step-by-Step Guide:</p>
                                                <ol className=''>
                                                <li>Fill Out the Application Form: Complete our online form with your personal details and program preference.</li> 
                                                <li>Entrance Test: Appear for an entrance test to assess your eligibility for the program.</li>
                                                <li>Final Admission: Based on your test results</li>
                                                </ol>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Scholarships & Financial Aid</Accordion.Header>
                                            <Accordion.Body>
                                                {/* <p>Aspiring students are admitted at ICAD based on their performance in ICAD’s admission & scholarship (CPA) test. Prospective ICADians are eligible for 10 - 100% waiver in their tuition fees based on their performance in <b>ICAD’s Scholarship & Admission Competitive Potential Assessment (CPA) Test.</b></p> */}
                                                <p className='mb-2'>ICAD offers scholarships to deserving students based on merit and financial need.</p>
                                                <ul className='check_list'>
                                                    <li>Merit Scholarships: Available to top-performing students.</li>
                                                    <li>Need-Based Scholarships: Supporting students from economically weaker sections.</li>
                                                    <li>How to Apply: Contact us for more details.</li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>Why ICAD is the Right Choice</Accordion.Header>
                                            <Accordion.Body>
                                                {/* <p>Aspiring students can download CPA sample paper at <a href='/downloads'>www.icadiit.com/downloads.php</a></p> */}
                                                <ul className='check_list'>
                                                    <li>Proven track record of successful students</li>
                                                    <li>Personalized mentorship</li>
                                                    <li>Comprehensive study materials and online resources</li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col sm={12} className='mb-15'>
                            <div className='btn_group float-end'>
                                {/* <Button className='theme_btn' onClick={() => setModalShow(true)}>Apply Now</Button> */}
                                <Button className='theme_btn' onClick={() => setModalShow(true)}>Book a Counseling Session</Button>
                            </div>                                
                        </Col>
                    </Row>
                </Container>
            </section>
            
            <FormModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
};

export default Admission;