import React, {useState, useEffect, useMemo} from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {useParams} from "react-router-dom";
import { Helmet } from 'react-helmet';

import Navigation from '../components/Navigation';

import blogData from '../data/blog';
import parse from 'html-react-parser';

const BlogDetails = props => {
    const {blogItemId} = useParams()
    const thisBlog = blogData.find(blog => blog.id === blogItemId);
    console.log(thisBlog);
    return(
        <>
            <Helmet>
                <title>Blog - ICAD Study Center</title>
                <meta name="description" content="Check our latest blog on exams" />
                <meta name="keywords" content="JEE preparation tips, NEET exam strategies, time management for students" />
            </Helmet>

            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>Blog</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item>Blog</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Blog Details</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50'>
                <Container>
                    <Row>
                        <Col sm={12}>                            
                            <h1 className='title mb-4'>{thisBlog.title}</h1>
                            <h6 className='mb-5'><span className='category_text'>{thisBlog.category}</span></h6>
                            <div className='thumbnail mb-4'>
                                <Image src={thisBlog.image} alt="blog image" fluid></Image>
                            </div>
                            <div>{parse(thisBlog.detail)}</div>
                            <div><p><b>Team ICAD</b></p></div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default BlogDetails;