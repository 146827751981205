import React from 'react';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Accordion from 'react-bootstrap/Accordion';

import Navigation from '../components/Navigation';

import icadTeaching from '../assets/images/icad-teaching.jpg';
import steps from '../assets/images/steps.jpg';


const AboutAcademics = props => {
    return (
        <>
            <div className='header_wrapper'>
                <Navigation />
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>About Academics</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>About Academics</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50'>
                <Container>
                    <Row className='mb-30'>
                        <Col sm={12}>
                            <div className='mb-30'>
                                <h1 className='title'>About Academics</h1>
                                <p>ICAD has well-established state-of-the-art academics. It believes in Mentoring students towards success. The only things students should have are determination, faith, and patience.</p>
                                <p>Teaching is only one of the fundamental aspects of mentoring. In ICAD, teaching is student oriented and it goes hand-in-hand with testing and monitoring. The other aspects of mentoring are motivation, development of positive attitude, time management skills, examination skills and problem solving skills, which builds a perfect personality to be an IITian. The most important aspect of mentoring is counseling, where the problem areas are identified with the help of feed-back system, which may be related to students or faculties or system and remedial measures are taken. ICAD believes that parents are also mentors along with the faculties and seeks their cooperation in the process of achieving the goal. All above aspects are clubbed together as 'Training'.</p>
                            </div>
                            <Card className='theme_card'>
                                <Card.Body>
                                    <Accordion className='theme_accordion' defaultActiveKey="0" flush>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Teaching</Accordion.Header>
                                            <Accordion.Body>
                                                <p>The syllabus for JEE/NEET is same as that of XI+XII State Board/CBSE in Physics, Chemistry & Mathematics/Biology. The whole difference is in the examination system and therefore study system needs to be completely changed. The training starts with teaching of board level concepts and students are guided to apply concepts to solve competitive questions. However it must be clearly understood that in the limited time above goal can be achieved only when students cooperate and come to the classes with prior reading of the topics from board books. Faculty will not be dictating the questions and answers from board books. After explanation in the class room, students are expected to write answers with their own mind, starting from board level (level-1 onwards to level-6).</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>ICAD Teaching Pedagogy</Accordion.Header>
                                            <Accordion.Body>
                                                <Image src={icadTeaching} alt="ICAD Teaching Pedagogy" className="d-block mx-auto" fluid></Image>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>Learning and Revising</Accordion.Header>
                                            <Accordion.Body>
                                                <p>Apart from teaching, Students must undertake self-study for at least four to eight hours per day depending on your ability & target. There is no minimum or maximum limit for number of hours of study; it depends on the efficiency of study technique. The major part of self-study must include 'learning' of the currently taught topic. 'Learning' does not mean 'reading' or 'mugging up', but it means problem solving in exam-like environment. Normally the ratio of reading to problem solving should be 1:3.</p>
                                                <p>Students must also revise entire acquired knowledge by continuously solving different types of problems in exam-like environment. They must undertake self-mock tests.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>ICAD'S Teaching System - Lecture Plans</Accordion.Header>
                                            <Accordion.Body>
                                                <p>Study for competitive exams such as JEE/NEET becomes unmanageable due to many facts such as the vastness of syllabus, short time, higher level & different style etc.</p>
                                                <p>The NCERT syllabus in Physics, Chemistry & Mathematics/Biology is roughly divided in to more than 120 chapters which are covered in schools in two years of XI & XII. As per NCERT about 360 hrs of teaching per subject is required to teach students to board level. However in board pattern, the question bank/ question pattern is limited & only XII portion is in final board examination.</p>
                                                <p>It also must be noted that we get limited time of about 70 teaching weeks, and we can at the most engage around 260-270 lectures per subject of one & half hrs duration (i.e about 400 hrs/subject). We also have to raise level of students from board to JEE/NEET. Moreover, it is observed that entry level is very poor with respect to competitive exams (though X board marks & school performance up to X is very good.)</p>
                                                <p>ICAD has developed a special system to overcome all above hurdles and create success stories. We have rearranged the teaching sequence of these topics so as to derive maximum benefit for JEE/NEET while taking due care of board exam. We have allocated different number of lectures to above topics and taken care to make student understand certain topics which form base for JEE/NEET, though there is no weightage in board and are normally skipped in board teaching.</p>
                                                <p>Students are given topic-wise booklets containing Lecture Plan, Compendium, Suggested class problems & Homework/self-study problems. Students know beforehand what is going to be covered in the class & are supposed to come to the class with prior reading of the contents from at least state board text book. They also know what problems exactly they have to solve in solving sessions at ICAD/Home on that day.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>Six steps solving system</Accordion.Header>
                                            <Accordion.Body>
                                                <p>The ultimate success in JEE/NEET depends on the problem solving practice. ICAD has evolved the strategy for the success; it consists of step-by-step solving practice. We have divided solving sessions in three broad groups</p>
                                                <ul className='check_list'>
                                                    <li>Subjective type-elementary level</li>
                                                    <li>Objective Type</li>
                                                    <li>Mix type-advanced level</li>
                                                </ul>
                                                <p>Each group is further divided in to two steps each, thus taking total to six steps.</p>
                                                <p><b>Step 1</b> is the lowest board level solving, directly from board books. This step ensures success in the board exam.</p>
                                                <p><b>Step 2</b> is selected problems from other books/Daily Practice Problem Sheets (DPPs). This takes students to higher-than-board level & orients them to CET/JEE/NEET.</p>
                                                <p><b>Step 3</b> consist of selected objective problems & is sufficient to take students to CET/JEE(Main)/NEET cracking level.</p>
                                                <p><b>Step 4</b> is solving many more objective problems & it ensures good rank in CET/JEE(Main)/NEET.</p>
                                                <p><b>Step 5</b> is mix type questions based on JEE(Advanced) pattern and takes students to JEE (Advanced) / AIIMS cracking level.</p>
                                                <p><b>Step 6</b> is still higher level mix type problems & ensures good rank in JEE(Advanced) / AIIMS.</p>
                                                <p>Students must solve problems step-wise. If they jump to higher step directly then they will be wasting more time unnecessarily and will not be able to answer simple questions in the exam.</p>
                                                <Image src={steps} alt="Steps Image" className='mt-3 mx-auto -d-block' fluid></Image>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header>testing and preparation</Accordion.Header>
                                            <Accordion.Body>
                                                <p>ICAD believes in work done by the students. It has a continuous testing system in the form of Mini Tests, Revision Tests (RT), Continuous Assessment Tests (CAT), Special Tests, and Final Test Series on JEE/Medical/Board (FTS).</p>
                                                <p>After every test result sheet is given to the student & score is sent to parents by sms.</p>
                                                <p>This system prepares students academically, mentally, physically and emotionally for the final JEE/Medical. The purpose of testing is not to see what are the marks scored, but is to identify the mistakes and weaker areas and to improve up on them.</p>
                                                <p>Preparation is a continuous process and is achieved through daily solving practice.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header>academic and testing standard</Accordion.Header>
                                            <Accordion.Body>
                                                <p>ICAD does not believe in goody-goody teaching and testing. Here teaching is done with an eye on the final target. First it takes students from zero level (irrespective of his/her Xth score) to board examination level and then to competitive examination or application level with emphasis on the problem solving technique. Students are required to completely change their study pattern and orient with ICAD strategy. Assignments (DPP) raise their level a bit higher and tests take them to much higher application level with all the desired skills to crack competitive examinations. The scores in these tests will never be as high as board or school examinations. A consistent performance of 40% plus in the CAT/RT is an indicator of potential to achieve success. Better performance in Revision tests is an indicator that the students are following the ICAD strategy.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="7">
                                            <Accordion.Header>doubt clearing</Accordion.Header>
                                            <Accordion.Body>
                                                <p>They must do it with a time-target and with an examination temperament. If they are having doubts, then it means that thet have started studying. They must get these doubts cleared from the books or from the faculty without piling them up, for which they must approach faculty with their half solved/ wrongly solved problems and discuss about mistakes/ methods.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="8">
                                            <Accordion.Header>monitoring, analysis and remedial measures</Accordion.Header>
                                            <Accordion.Body>
                                                <p>Students are being mentored on regular basis by Team ICAD which includes all from top to bottom. ICAD has developed unique methodology which helps us to monitor the progress and guide for further improvement. Under this system, students are provided with Success Secrets booklet, Syllabus, teaching & testing schedule, lecture plans and two very important tools -solving record booklet (SRB) & test analysis booklet (TAB).</p>
                                                <p>At ICAD we train the students how to fill SRB. They are advised to fill the SRB on day to day basis regarding the solving work they do at ICAD or at home. It keeps track of time bound work done by the student. It helps all-Team ICAD, parents and most important the student himself to see how time is utilized and its quality. Doubt clearing is also provided but after putting their own efforts in a written manner.</p>
                                                <p>Other tool TAB is very effective in pin pointing the mistakes that are being committed by the students in tests. After every test, students are advised to fill up the TAB without fail. It helps them to identify their mistakes themselves whether it is conceptual, calculation or rush hour mistake- labeled as silly mistake. When students come for counseling with these 2 booklets filled up, they can be guided pin-pointedly & remedial measures can be provided.</p>
                                                <p>However, it must be noted that improvement has to be internal desire of student. As such he has to come forward for counseling. He should be receptive, ready to accept mistakes and ready to eliminate them. Forcefully coming to counseling because of parent's pressure and just sitting passively does no good. Attitude-wrong or negative, overconfidence are biggest road blocks in the path of success.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="9">
                                            <Accordion.Header>teacher role</Accordion.Header>
                                            <Accordion.Body>
                                                <p>Teacher's role at ICAD is very specific, they are specialized in dealing with competitive examinations. Teaching as per our Lecture Plan is only one small part of their job, whereas mentoring students is the major part and they are constantly doing it under guidance of Director (Academics).</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="10">
                                            <Accordion.Header>discipline</Accordion.Header>
                                            <Accordion.Body>
                                                <p>At ICAD sincerity and honesty is inculcated right from the beginning to make student more productive in the efforts. The course is professionally managed which prepares students to enter professional courses of highest standards. Students are therefore required to be regular in every field i.e. class-room teaching, effective self-study, doubt clearing, home work, tests throughout the course duration.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="11">
                                            <Accordion.Header>progress resports and news letters</Accordion.Header>
                                            <Accordion.Body>
                                                <p>The progress reports are sent to parents on a periodic basis. The test results are sent by SMS to parents and are also available on web site. ICAD also gives insight to every student about the goal, the rigorous path, strategies through periodic interactive sessions, News Letters/SMS.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="12">
                                            <Accordion.Header>icad online</Accordion.Header>
                                            <Accordion.Body>
                                                <p>www.icadiit.com offers personalized webpage to all ICADians. On this webpage, ICADians and their parents can view candidate's attendance report, performance in various tests, comparison with toppers, and important announcements. The website is being updated fortnightly. Students can also solve various tests on this website.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>                   
                </Container>
            </section>
           
        </>
    );
};

export default AboutAcademics;