import React, {useState} from 'react';
import { Container, Row, Col, Tab, Nav, Image} from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Navigation from '../components/Navigation';
import Modal from '../components/Modal';

import mainImage from '../assets/images/dis-academics.png';
import academics1 from '../assets/images/academics/1.jpg';
import academics2 from '../assets/images/academics/2.png';
import academics3 from '../assets/images/academics/3.png';
import academics4 from '../assets/images/academics/4.png';
import academics5 from '../assets/images/academics/5.png';
import academics6 from '../assets/images/academics/6.png';
import academics7 from '../assets/images/academics/7.png';
import academics8 from '../assets/images/academics/8.png';
import academics9 from '../assets/images/academics/9.png';
import academics10 from '../assets/images/academics/10.png';
import academics11 from '../assets/images/academics/11.png';
import academics12 from '../assets/images/academics/12.png';


const DistinguishingAcademics = props => {
    const [isOpen, setIsOpen] = useState(false);
    // const [modalTitle, setModalTitle] = useState('');
    const [modalBody, setModalBody] = useState('');
    const [modalSize, setModalSize] = useState('md');

    const openModal = (body, size) => {
        // setModalTitle(title);
        setModalBody(body);
        setModalSize(size);
        setIsOpen(true);
      };
    
      const closeModal = () => {
        setIsOpen(false);
        // setModalTitle('');
        setModalBody('');
      };
      const image1 = () =>{
        return(
            <Image src={academics1} alt="learning techniques" fluid></Image>
        );
      };
      const image2 = () =>{
        return(
            <Image src={academics2} alt="learning techniques" fluid></Image>
        );
      };
      const image3 = () =>{
        return(
            <Image src={academics3} alt="learning techniques" fluid></Image>
        );
      };
      const image4 = () =>{
        return(
            <Image src={academics4} alt="learning techniques" fluid></Image>
        );
      };
      const image5 = () =>{
        return(
            <Image src={academics5} alt="learning techniques" fluid></Image>
        );
      };
      const image6 = () =>{
        return(
            <Image src={academics6} alt="learning techniques" fluid></Image>
        );
      };
      const image7 = () =>{
        return(
            <Image src={academics7} alt="learning techniques" fluid></Image>
        );
      };
      const image8 = () =>{
        return(
            <Image src={academics8} alt="learning techniques" fluid></Image>
        );
      };
      const image9 = () =>{
        return(
            <Image src={academics9} alt="learning techniques" fluid></Image>
        );
      };
      const image10 = () =>{
        return(
            <Image src={academics10} alt="learning techniques" fluid></Image>
        );
      };
      const image11 = () =>{
        return(
            <Image src={academics11} alt="learning techniques" fluid></Image>
        );
      };
      const image12 = () =>{
        return(
            <Image src={academics12} alt="learning techniques" fluid></Image>
        );
      };

    return(
        <>
            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>Distinguishing Academics</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Distinguishing Academics</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000" id='distingusing-academics'>
                <Container>
                    <h1 className='title'>Distinguishing Academics</h1>
                    <h3 className=''>What makes ICAD vidarbha's best institute</h3>
                    <p>The only Institute to hold your hand throughout your entire journey untill your final success.</p>
                    <div className='vertical_tabs buttons academics_tabs'>
                        <Tab.Container id="left-tabs-example">
                            <Row>
                                <Col sm={12}>                                        
                                    <Nav variant="pills">
                                        <Nav.Item>
                                            <Nav.Link onClick={() => openModal(image1, 'md')}>learning techniques</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => openModal(image2, 'md')}>command capsule</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => openModal(image3, 'md')}>online app</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => openModal(image4, 'md')}>result booster</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => openModal(image5, 'md')}>doubt clearing</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => openModal(image6, 'md')}>discipline</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => openModal(image7, 'md')}>faculty</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => openModal(image8, 'md')}>academic system</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => openModal(image9, 'md')}>solving system</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => openModal(image10, 'md')}>personal attention</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => openModal(image11, 'md')}>result creation system</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => openModal(image12, 'md')}>mentorship</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>                              
                            </Row>
                            <Row className='mt-5'>
                                <Col sm={12}>
                                    <Image src={mainImage} alt="main image" fluid></Image>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </Container>                    
            </section>
            <Modal isOpen={isOpen} onClose={closeModal} body={modalBody} size={modalSize} />
        </>
    )
}

export default DistinguishingAcademics