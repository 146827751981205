import React from 'react';
import { Container, Row, Col, Tabs, Tab, Image} from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Navigation from '../components/Navigation';

import olympaid from '../assets/images/icad-star/olympaid.jpg';
import inmoStudent1 from '../assets/images/icad-star/INMO1.png';
import inmoStudent2 from '../assets/images/icad-star/INMO2.png';
import inmoStudent3 from '../assets/images/icad-star/INMO3.png';
import inmoStudent4 from '../assets/images/icad-star/INMO4.png';
import inmoStudent5 from '../assets/images/icad-star/INMO5.png';
import inchoStudent from '../assets/images/icad-star/incho.png';

const IcadStars = props => {
    return(
       <main>
          <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>   
                            <div className='breadcrumb_content'>
                                <h1>ICAD Stars</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>ICAD Stars</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section  data-aos="fade-up" data-aos-duration="3000" className='mt-50'>
                <Container>   
                    <h1 className='title mb-30'>ICAD Stars</h1>                 
                    <Tabs
                        id="justify-tab-example"
                        defaultActiveKey="1"
                        className="horizontal_tabs star_tabs"
                        justify                    
                        >                            
                            <Tab eventKey='1' title='Olympiads'>
                                <div>
                                    <h1 className='title'>Olympaids</h1>
                                    <p className='mb-30'>Olympiads are a kind of competitive exam, held across schools, to tap exceptional students with the best of potential, talent, aptitude, and IQ.</p>
                                    <Image src={olympaid} alt="Olympaid result" className='d-block mx-auto' fluid></Image>
                                </div>
                            </Tab>
                            <Tab eventKey='2' title='INMO'>
                                <div>
                                    <h1 className='title'>Indian National Mathematics Olympiad</h1>
                                    <p>Students who qualify through the RMO and are in classes 8 to 12 can appear for the INMO exam</p>
                                    <h2 className='subtitle text-center mb-30'>6 ICADians are selected for Indian National Mathematics Olympiad (INMO) from Nagpur in 2019</h2>
                                    <Row>
                                        <Col lg={3} md={6} sm={12} className='mb-3'>
                                            <Image src={inmoStudent1} alt="student image" className='d-block mx-auto' fluid></Image>
                                        </Col>
                                        <Col lg={3} md={6} sm={12} className='mb-3'>
                                            <Image src={inmoStudent2} alt="student image" className='d-block mx-auto' fluid></Image>
                                        </Col>
                                        <Col lg={3} md={6} sm={12} className='mb-3'>
                                            <Image src={inmoStudent3} alt="student image" className='d-block mx-auto' fluid></Image>
                                        </Col>
                                        <Col lg={3} md={6} sm={12} className='mb-3'>
                                            <Image src={inmoStudent4} alt="student image" className='d-block mx-auto' fluid></Image>
                                        </Col>
                                        <Col lg={3} md={6} sm={12} className='mb-3'>
                                            <Image src={inmoStudent5} alt="student image" className='d-block mx-auto' fluid></Image>
                                        </Col>
                                    </Row>
                                </div>
                            </Tab>
                            <Tab eventKey='3' title='INChO'>
                                <div>
                                    <h1 className='title'>Indian National Chemistry Olympiad (INChO)</h1>
                                    <p>To appear for the InChO exam, one must qualify for the NSEC exam. Students after passing 11th and 12th grade can take part in InCHO after qualifying for the first stage of the examination</p>
                                    <h2 className='subtitle text-center mb-30'>Top 42 of India</h2>
                                    <Row className='justify-content-center'>
                                        <Col lg={3} md={6} sm={12} className='mb-3 text-center'>
                                            <Image src={inchoStudent} alt="student image" className='d-block mx-auto' fluid></Image>
                                        </Col>
                                    </Row>
                                </div>
                            </Tab>
                            <Tab eventKey='4' title='INPhO'>
                                <div>
                                    <h1 className='title'>Indian National Physics Olympiad (INPhO)</h1>
                                    <p>Students in grades 11 and 12 first should take the NSEP exam to qualify for the INPhO exam. This exam is to screen applicants for the Physics Orientation-Cum-Selection-Camp as well as to represent India in the International Physics Olympiad exam.</p>
                                </div>
                            </Tab>
                            <Tab eventKey='5' title='RMO'>
                                <div>
                                    <h1 className='title'>Regional Mathematics Olympiad (RMO)</h1>
                                    <p>To be eligible for RMO 2024, a student must score at least 10% of the total marks in the IOQM 2024 paper. However, this is not the only qualifying criterion. Students who score at least 10% of the total marks and are enrolled in classes 8, 9, 10, or 11 during IOQM 2024 will be classified as Category A students.</p>
                                </div>
                            </Tab>
                            <Tab eventKey='6' title='AMTI'>
                                <div>
                                    <h1 className='title'>National Mathematics Talent Contest by AMTI</h1>
                                    <p>The Association of Mathematics Teachers of India or AMTI is an academically oriented body of professionals and students interested in the fields of mathematics and mathematics education.</p>
                                </div>
                            </Tab>

                    </Tabs>
                </Container>
            </section>
       </main>
    );
};

 export default IcadStars;